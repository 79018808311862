<template>
  <footer>
    <div class="company-info">
      <div class="left subscription">
        <p class="tips" v-html="$t('footer.interestOne')"></p>
        <template v-if="newsletterSuccess">
          <p>{{ $t('footer.success') }}</p>
        </template>
        <template v-else>
          <el-input :placeholder="$t('footer.inputEmail')" v-model="newsletterEmail" class="newsletter-email-input mt-4">
            <i slot="suffix" class="el-input__icon el-icon-right" @click="saveNewsLetter"></i>
          </el-input>
        </template>
      </div>


      <div class="right">
        <p v-if="address">{{ $t('footer.address') }}：{{ (currentLanguage === 'en' && en_address) ? en_address : address }}</p>
        <p v-if="zip">{{ $t('footer.zip') }}：{{ zip }}</p>
        <p v-if="tel">{{ $t('footer.phone') }}：{{ tel }}</p>
        <p v-if="fax">{{ $t('footer.fax') }}：{{ fax }}</p>
        <p v-if="business_support">{{ $t('footer.businessSupport') }}：{{ business_support }}</p>
        <p v-if="technical_support">{{ $t('footer.technicalSupport') }}：{{ technical_support }}</p>
      </div>
    </div>
    <div class="copyright d-flex justify-content-between align-items-center">
      <div>
        <a class="copyright-link" href="http://beian.miit.gov.cn/" target="_blank">{{ $t('footer.copyright') + record_number || '' }}</a>
      </div>

      <div class="social-media position-relative">
        <el-popover placement="top" trigger="hover" :append-to-body="false" :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,
                              }">
          <img :src="wechatUrl" style="width: 150px; height: 150px;" alt=""/>
          <img slot="reference" class="social-media-icon" :src="wechat" alt=""/>
        </el-popover>
        <a v-if="currentLanguage === 'cn'" :href="sinaUrl" target="_blank"><img class="social-media-icon" :src="sina" alt=""/></a>
        <a :href="currentLanguage === 'en' ? enLinkInUrl:linkInUrl" target="_blank"><img class="social-media-icon" :src="linkIn" alt=""/></a>
        <a v-if="currentLanguage === 'en'" :href="twitterUrl" target="_blank"><img class="social-media-icon" :src="twitter" alt=""/></a>
        <a v-if="currentLanguage === 'en'" :href="facebookUrl" target="_blank"><img class="social-media-icon" :src="facebook" alt=""/></a>
      </div>
    </div>
  </footer>
</template>

<script>
import switchLanguageIcon from '../assets/images/switch_language.png'
import logo from '../assets/images/logo_white.png'
import wechat from '../assets/images/icon_wechat.png'
import sina from '../assets/images/icon_sina.png'
import linkIn from '../assets/images/icon_linkin.png'
import twitter from '../assets/images/icon_twitter.png'
import facebook from '../assets/images/icon_facebook.png'

export default {
  name: "CentecFooter",
  data() {
    return {
      switchLanguageIcon,
      logo,
      wechat,
      wechatUrl: null,
      sina,
      sinaUrl: null,
      linkIn,
      linkInUrl: null,
      enLinkInUrl: null,
      twitter,
      twitterUrl: null,
      facebook,
      facebookUrl: null,
      record_number: null,
      tel: null,
      fax: null,
      business_support: null,
      technical_support: null,
      address: null,
      en_address: null,
      zip: null,
      currentLanguage: this.$store.state.language,
      language: [{
        value: 'cn',
        label: '中文'
      }, {
        value: 'en',
        label: '英文'
      }],
      newsletterEmail: null,
      newsletterSuccess: false
    }
  },
  mounted() {
    this.getZip()
    this.getSocialMedia()
  },
  methods: {
    switchLanguage() {
      let targetLanguage = (this.currentLanguage === 'cn' ? 'en' : 'cn')
      this.language = targetLanguage
      this.$i18n.locale = targetLanguage
      this.$store.commit('changeLanguage', targetLanguage)
      window.location.reload(true)
      return false
    },
    getZip() {
      let _this = this
      Http.get(`/api/v1/recruitments/get_job_email`).then(result => {
        if (result?.code === 0) {
          if (result.data) {
            _this.record_number = result.data.record_number
            _this.tel = result.data.tel
            _this.fax = result.data.fax
            _this.business_support = result.data.business_support
            _this.technical_support = result.data.technical_support
            _this.address = result.data.address
            _this.en_address = result.data.en_address
            _this.zip = result.data.zip
          }
        }
      }, error => {
        console.info(error)
      })
    },
    saveNewsLetter: function () {
      let _this = this
      if (!_this.newsletterEmail) {
        _this.$message.error(`${this.i18n.t('footTips.verificationEmail')}`)
        return false
      } else if (!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(_this.newsletterEmail))) {
        _this.$message.error(`${this.i18n.t('footTips.verificationEmail')}`)
        return false
      }
      Http.post('/api/v1/newsletter', { email: _this.newsletterEmail }).then(result => {
        if (result.code === 0) {
          _this.newsletterSuccess = true
          this.$notify({
            title: result.data,
            type: 'success'
          });
        } else {
          _this.$message.error(result.message)
        }
      }, error => {
        _this.$message.error(`${this.i18n.t('footTips.error')}`)
      })
    },
    getSocialMedia: function () {
      let _this = this
      Http.get('/api/v1/setting/get_setting', { params: { title: 'social_media' } }).then(result => {
        if (result.code === 0) {
          if (result.data) {
            let data = JSON.parse(result.data)
            _this.wechatUrl = data.filter(item => item.name === 'wechat')[0]?.link
            _this.sinaUrl = data.filter(item => item.name === 'sina')[0]?.link
            _this.linkInUrl = data.filter(item => item.name === 'LinkedIn')[0]?.link
            _this.enLinkInUrl = data.filter(item => item.name === 'EnLinkedIn')[0]?.link
            _this.twitterUrl = data.filter(item => item.name === 'Twitter')[0]?.link
            _this.facebookUrl = data.filter(item => item.name === 'Facebook')[0]?.link
          }
        }
      }, error => {
        console.info(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// 切换语言的箭头
.switch-language {
  color: #fff !important;
  margin-right: 1rem;

  img {
    width: 1rem;
    vertical-align: sub;
    margin-right: .5rem;
  }
}

footer {
  background: #3A7DC5;
  color: #fff;

  .quick-link {
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
    padding: 2rem 0;

    .item {
      .title {
        line-height: 3;
        padding: .5rem 0;
        font-size: 1.2rem;
      }

      .links {
        a {
          display: block;
          font-size: 1rem;
          line-height: 2;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .company-info {
    padding: 2rem 0;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: flex-end;
    align-items: flex-end;

    .subscription {
      font-size: 1rem;
      line-height: 1.5;

      .tips {
        margin-bottom: 0;
      }
    }

    .right {
      padding: 0 15px;

      .address {
        line-height: 2rem;
        margin-bottom: 1rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
        line-height: 2;
      }
    }
  }

  .copyright {
    background: #464646;
    padding: 1rem 5%;
    width: 90%;
    margin: 0 auto;
    font-size: .8rem;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    align-content: center;
    align-items: center;

    .copyright-link {
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    .social-media {
      a, span {
        .social-media-icon {
          width: 1rem;
          margin-right: 1rem;
          vertical-align: middle;
          pointer-events: auto;

        }

        &:last-child {
          .social-media-icon {
            margin-right: 0;
          }

        }
      }

    }
  }
}

@media only screen and (max-width: 992px) {
  footer {
    .quick-link {
      flex-wrap: wrap;
      padding: 2rem;

      .item {
        flex: 50%;

        .title {
          padding-top: 2rem;
        }
      }
    }

    .company-info {
      width: auto;
      padding: 1rem 2rem;
      flex-direction: column;
      align-items: flex-start;

      .left {
        width: 100%;

        .tips {
          display: inline-block;
          margin-bottom: 0;
        }
      }

      .right {
        width: 100%;
        margin-top: 1rem;
        padding: 0;
      }
    }

    .copyright {
      flex-direction: column-reverse;
      line-height: 2;
      font-size: .9rem;
      text-align: center;

      .copyright-link {
        color: #fff;
      }

      .social-media {
        width: 100%;
        justify-content: space-around;
        margin-bottom: 1rem;

        .social-media-icon {
          width: 1.2rem;
          vertical-align: middle;
        }
      }
    }
  }

}
</style>

<style lang="scss">
.el-notification__title {
  line-height: 24px;
}

footer {
  .newsletter-email-input {
    input {
      background: #3A7DC5;
      color: #fff;
      border-color: #fff !important;
      border-radius: 0;

      &:hover {
        border-color: #fff;
      }

      &::placeholder {
        color: #fff;
      }
    }

    .el-input__icon {
      color: #fff;
    }

  }
}
</style>