import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		centecToken: localStorage.getItem('CentecToken'),
		language: localStorage.getItem('CentecLanguage') || 'cn',
		currentUser: null,
		currentUserMenuList: [],
		currentUserActionList: [],
		isLogin: false,
		showLoginWindow: false
	},
	mutations: {
		changeLogin(state, token) {
			state.centecToken = token
			localStorage.setItem('CentecToken', token)
		},
		changeLanguage(state, language) {
			state.language = language
			localStorage.setItem('CentecLanguage', language)
		},
		changeCurrentUser(state, currentUser) {
			state.isLogin = true
			state.currentUser = currentUser
			localStorage.setItem('access_level', currentUser.access_level)
		},
		logout(state) {
			sessionStorage.removeItem('member_keyword');
			localStorage.removeItem('CentecToken')
			localStorage.removeItem('access_level')
			state.centecToken = null
			state.currentUser = null
			state.isLogin = false
			state.currentUserMenuList = []
			state.currentUserActionList = []
		},
		changeCurrentUserMenuList(state, menu) {
			state.currentUserMenuList = menu
		},
		changeCurrentUserActionList(state, action) {
			state.currentUserActionList = action
		},
		changeShowLoginWindow(state) {
			state.showLoginWindow = !state.showLoginWindow
		},
		isLoginTrue(state) {
			state.isLogin = true
		},
		isLoginFalse(state) {
			state.isLogin = false
		}
	},
	getters: {
		getCentecToken: state => {
			return state.centecToken || localStorage.getItem('CentecToken')
		},
		getCurrentUser: state => {
			return state.currentUser
		},
		checkMenuList: state => menu => {
			return state.currentUserMenuList.filter(item => item.menu_value === menu).length > 0
		},
		checkActionList: state => (controller, action) => {
			return state.currentUserActionList.filter(item => item.controller === controller && item.action === action).length > 0
		}
	},
	actions: {
		getFrontendUserInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				if (!!state.currentUser) {
					resolve()
					return
				}
				Http.get(`/api/v1/userinfo`).then(result => {
					if (result.code === 0 && result.data) {
						commit('changeCurrentUser', result.data)
						resolve()
					} else {
						commit('logout')
						reject(result.message)
					}
				}).catch((error) => {
					reject(error)
				})
				
			})
		},
		getInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				if (!!state.currentUser) {
					resolve()
					return
				}
				Http.get(`/api/v1/admin/userinfo`).then(result => {
					if (result.code === 0 && result.data) {
						commit('changeCurrentUser', result.data)
						resolve()
					} else {
						commit('logout')
						reject(result.message)
					}
				}).catch((error) => {
					reject(error)
				})
				
			})
		},
		getFunction({ commit, state }) {
			return new Promise((resolve, reject) => {
				if (state.currentUserMenuList.length > 0 && state.currentUserActionList.length > 0 ) {
					resolve()
					return
				}
				Http.get(`/api/v1/admin/get_menu_by_token`).then(result => {
					if (result.code === 0) {
						if (result.data){
							commit('changeCurrentUserMenuList', result.data.filter(item => item.is_menu))
							commit('changeCurrentUserActionList', result.data.filter(item => !item.is_menu))
						}
						resolve()
					} else {
						reject(result.message)
					}
				}).catch((error) => {
					reject(error)
				})
			})
		}
	}
})
