import axios from 'axios'
import store from "../store";

const Http = axios.create({
  withCredentials: true, // 跨域请求时发送 cookies
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  }
})

// request interceptor
Http.interceptors.request.use(config => {

  if (store.state.centecToken) {
    config.headers.Token = store.state.centecToken
  }
  if (store.state.language) {
    config.headers.lang = store.state.language
  } else {
    config.headers.lang = 'cn'
  }
  return config
}, error => {
  console.info(`[${new Date()}]: Http Interceptors Request Error: ${error.message}`)
  return Promise.reject(error)
})

// response interceptor
Http.interceptors.response.use(response => {
  if (response.status !== 200) {
    let error = new Error('请求失败')
    switch (response.status) {
      case 400: error.message = '请求错误(400)'; break
      case 401: error.message = '未授权，请重新登录(401)'; break
      case 403: error.message = '拒绝访问(403)'; break
      case 404: error.message = '请求出错(404)'; break
      case 408: error.message = '请求超时(408)'; break
      case 500: error.message = '服务器错误(500)'; break
      case 501: error.message = '服务未实现(501)'; break
      case 502: error.message = '网络错误(502)'; break
      case 503: error.message = '服务不可用(503)'; break
      case 504: error.message = '网络超时(504)'; break
      case 505: error.message = 'HTTP版本不受支持(505)'; break
      default: error.message = `连接出错(${response.status})!`
    }
    return Promise.reject(error)
  } else {
    const res = response.data
    if (res) {
      return Promise.resolve(res)
    } else {
      return Promise.reject(new Error(res.message || '请求失败'))
    }
  }
}, error => {
  console.info(`[${new Date()}]: Http Interceptors Response Error: ${error.message}`)
  return Promise.reject(error)
})

export default Http
