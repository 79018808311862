<template>
    <div class="CentecRightNavigation">
        <div class="item navigation-list">
            <div class="title">
                <router-link :to="routerTitle.router">{{ $t(routerTitle.label) }}</router-link>
            </div>
            <p v-for="item in routerList" :key="item.label">
                <router-link :to="item.router">{{ $t(item.label)}}</router-link>
            </p>
        </div>
        <div class="item big-event" v-if="currentLanguageBigEventList.length > 0">
            <div class="title">{{ $t('header.big_event') }}</div>
            <div v-for="news in currentLanguageBigEventList" :key="news.id" class="mb-4">
                <router-link :to="'/news/' + news.id">
                    <div v-text="currentLanguageIsChinese ? news.title : news.en_title"/>
                    <p class="date" v-text="news.created_at"/>
                </router-link>
            </div>
            <p>
                <router-link to="/news" class="load-more">
                    {{ $t('header.load_more') }}
                    <i class="el-icon-arrow-right"/>
                </router-link>
            </p>
        </div>
        
        <div class="item recruitment" v-if="recruitmentList.length > 0">
            <div class="title">{{ $t('header.join') }}</div>
            <p v-for="recruitment in recruitmentList" :key="recruitment.id">
                <router-link to="/recruitment" v-text="currentLanguageIsChinese ? recruitment.title: recruitment.en_title"/>
            </p>
        </div>
        <router-link to="/contact_us">
            <div class="item contact">
                <div class="title">{{ $t('header.contact') }}</div>
                <div>
                    <p v-if="tel">{{ $t('header.phone') + tel }}</p>
                    <p v-if="fax">{{ $t('header.fax') + fax }}</p>
                    <p v-if="zip">{{ $t('header.zip_code') + zip }}</p>
                    <p v-if="!currentLanguageIsChinese && en_address" >{{ $t('header.address') + en_address}}</p>
                    <p v-if="currentLanguageIsChinese && address" >{{ $t('header.address') + address}}</p>

                </div>
            </div>
        </router-link>
    </div>
</template>
<style scoped lang="scss">
    .CentecRightNavigation {
        position: relative;
        z-index: 99;
        margin-top: -90px;
        
        .item {
            margin-bottom: 15px;
            padding: 20px 30px;
            background: #F4F4F6;
            
            .title {
                font-size: 20px;
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 10px;
                
                &:after {
                    content: ' ';
                    position: absolute;
                    background: #3A7DC5;
                    width: 40px;
                    height: 3px;
                    bottom: 0;
                    left: 0;
                }
            }
            
            a, a:hover, a:focus, a:visited {
                line-height: 2;
                color: #636363 !important;
                text-decoration: none;
            }
            
            &.navigation-list {
                background: #3A7DC5;
                
                .title {
                    &:after {
                        background: #ED6D22;
                    }
                }
                
                a, a:hover, a:focus, a:visited {
                    color: #fff !important;
                }
            }
            
            &.big-event {
                a, a:hover, a:focus, a:visited {
                    font-size: 14px;
                    color: #363636 !important;
                    
                    .date {
                        color: #b4b4b4;
                    }
                    
                    &.load-more {
                        color: #3A7DC5 !important;
                    }
                }
                
            }
            
            &.contact {
                p {
                    line-height: 2;
                }
                
                color: #363636;
                
                .router-link-active {
                    color: #363636;
                }
            }
        }
        
    }
</style>
<script>
    export default {
        name: 'CentecRightNavigation',
        props: {
            listType: {
                type: String,
                default() {
                    return 'about'
                }
            }
        },
        data() {
            return {
                bigEventList: [],
                recruitmentList: [],
                aboutList: [
                    {
                        router: "/about_us",
                        label: "header.about_centec"
                    },
                    {
                        router: "/recruitment",
                        label: "header.join"
                    },
                    {
                        router: "/news",
                        label: "header.news"
                    },
                    {
                        router: "/compliance_statement",
                        label: "header.compliance_statement"
                    },
                    {
                        router: "/branch",
                        label: "header.agent"
                    },
                    // {
                    //     router: "/cooperative_partner",
                    //     label: "header.partner"
                    // },
                    {
                        router: "/company_honor",
                        label: "header.honor"
                    },
                    {
                        router: "/contact_us",
                        label: "header.contact"
                    }
                ],
                personalList: [
                    {
                        router: "/BasicInformation",
                        label: "personalNavigation.basicInformation"
                    },
                    {
                        router: "/DownloadList",
                        label: "personalNavigation.downloadList"
                    },
                    {
                        router: "/SecurityCenter",
                        label: "personalNavigation.securityCenter"
                    }
                ],
                technicalList: [
                    {
                        router: "/TechnicalSupport",
                        label: "technicalNavigation.download"
                    },
                    {
                        router: "/CommonProblem",
                        label: "technicalNavigation.commonProblem"
                    },
                    {
                        router: "/ProductConsultation",
                        label: "technicalNavigation.productConsultation"
                    },
                ],
                record_number: null,
                tel: null,
                fax: null,
                business_support: null,
                technical_support: null,
                address: null,
                en_address: null,
                zip: null
            }
        },
        computed: {
            currentLanguageIsChinese() {
                return this.$store.state.language === 'cn'
            },
            currentLanguageBigEventList() {
                return this.currentLanguageIsChinese ? this.bigEventList.filter(item => !!item.lang_ch) : this.bigEventList.filter(item => !!item.lang_en)
            },
            routerList: function () {
                switch (this.listType) {
                    case 'about':
                        return this.aboutList
                    case 'technical':
                        return this.technicalList
                    case 'personal':
                        return this.personalList
                    default:
                        return this.aboutList
                }
            },
            routerTitle: function () {
                switch (this.listType) {
                    case 'about':
                        return { router: "/about_us", label: "header.about" }
                    case 'technical':
                        return { router: "/TechnicalSupport", label: "technicalNavigation.support" }
                    case 'personal':
                        return { router: "/BasicInformation", label: "personalNavigation.personal" }
                    default:
                        return { router: "/about_us", label: "header.about" }
                }
            }
        },
        created() {
            this.loadBigEvents()
            this.loadRecruitment()
            this.getZip()
        },
        methods: {
            loadBigEvents() {
                let _this = this
                Http.get('/api/v1/latest_big_event').then(result => {
                    if (Number.parseInt(result?.code) === 0 && result?.data) {
                        _this.bigEventList = result.data
                    }
                })
            },
            loadRecruitment() {
                let _this = this
                Http.get('/api/v1/latest_recruitment').then(result => {
                    if (Number.parseInt(result?.code) === 0 && result?.data) {
                        _this.recruitmentList = result.data
                    }
                })
            },
            getZip() {
                let _this = this
                Http.get(`/api/v1/recruitments/get_job_email`).then(result => {
                    if (result?.code === 0) {
                        if (result.data) {
                            _this.record_number = result.data.record_number
                            _this.tel = result.data.tel
                            _this.fax = result.data.fax
                            _this.business_support = result.data.business_support
                            _this.technical_support = result.data.technical_support
                            _this.address = result.data.address
                            _this.en_address = result.data.en_address
                            _this.zip = result.data.zip
                        }
                    }
                }, error => {
                    console.info(error)
                })
            }
        }
    }
</script>
