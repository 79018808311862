<template>
	<el-container>
		<el-header class="admin-header">
			<el-menu default-active='' class="admin-header-menu" mode="horizontal" background-color="#3A7DC5" text-color="#fff" active-text-color="#3A7DC5">
				<el-menu-item index="pc-logo" class="pc-logo hidden-xs-only">
					<img src="../../assets/images/logo_white.png" alt="">
					<i class="el-icon-s-operation" @click="handleAsideCollapse"/>
				</el-menu-item>
				<el-menu-item index="mobile-logo" class="mobile-logo hidden-sm-and-up" @click="drawer = true">
					<i class="el-icon-s-operation"/>
					<img src="../../assets/images/logo_white.png" alt="">
				</el-menu-item>

				<el-submenu index="user" class="user">
					<template slot="title">{{this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.member_name : '' }}</template>
					<el-menu-item index="user-logout" @click="changePassword">修改密码</el-menu-item>
					<el-menu-item index="user-logout" @click="logout">退出</el-menu-item>
				</el-submenu>
				<el-menu-item index="office-website" class="office-website hidden-xs-only">
					<router-link to="/">盛科官网</router-link>
				</el-menu-item>
			</el-menu>
		</el-header>

		<el-container>
			<el-aside :width="asideWidth" class="admin-aside hidden-xs-only">
				<el-menu :default-active="this.$route.meta.asideMenu" class="admin-aside-menu" :collapse-transition="false" :unique-opened="true" :collapse="isCollapse" :router="true">

					<el-menu-item index="AdminIndex" :route="{path: '/admin/index'}" >
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminIndex' }, 'admin-aside-menu-icon admin-aside-menu-icon_index']"/>
						<span slot="title">网络后台</span>
					</el-menu-item>
					<el-menu-item index="AdminCarousel"  :route="{path: '/admin/carousel/index'}" v-if="showMenu('AdminCarousel')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminCarousel' }, 'admin-aside-menu-icon admin-aside-menu-icon_carousel']"/>
						<span slot="title">轮播管理</span>
					</el-menu-item>

					<el-menu-item index="AdminSilicon" :route="{path: '/admin/silicon/index'}" v-if="showMenu('AdminSilicon')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSilicon' }, 'admin-aside-menu-icon admin-aside-menu-icon_silicon']"/>
						<span slot="title">芯片管理</span>
					</el-menu-item>

					<el-menu-item index="AdminSoftware" :route="{path: '/admin/software/index'}" v-if="showMenu('AdminSoftware')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSoftware' }, 'admin-aside-menu-icon admin-aside-menu-icon_software']"/>
						<span slot="title">软件管理</span>
					</el-menu-item>

					<el-menu-item index="AdminSolution" :route="{path: '/admin/solution/index'}" v-if="showMenu('AdminSolution')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSolution' }, 'admin-aside-menu-icon admin-aside-menu-icon_solution']"/>
						<span slot="title">解决方案管理</span>
					</el-menu-item>
					<el-submenu index="TechnicalSupport" v-if="showMenu('TechnicalSupport')">
						<template slot="title">
							<i :class="[{ active: this.$route.meta.asideMenu === 'TechnicalSupport' }, 'admin-aside-menu-icon admin-aside-menu-icon_support']"/>
							<span>技术支持</span>
						</template>
						<el-menu-item index="AdminDocument" :route="{path: '/admin/document'}" v-if="showMenu('AdminDocument')">文档管理</el-menu-item>
						<el-menu-item index="DownloadHistoryIndex" :route="{path: '/admin/technical/download_history/index'}" v-if="showMenu('DownloadHistoryIndex')">下载历史</el-menu-item>
						<el-menu-item index="CommonProblemIndex" :route="{path: '/admin/technical/common_problem'}" v-if="showMenu('CommonProblemIndex')">常见问题</el-menu-item>
						<el-menu-item index="ProductConsultationIndex" :route="{path: '/admin/technical/product_consultation'}" v-if="showMenu('ProductConsultationIndex')">产品咨询</el-menu-item>
					</el-submenu>
					<el-submenu index="AdminAbout" v-if="showMenu('AdminAbout')">
						<template slot="title">
							<i :class="[{ active: this.$route.meta.asideMenu === 'AdminAbout' }, 'admin-aside-menu-icon admin-aside-menu-icon_about']"/>
							<span>关于盛科</span>
						</template>
						<el-menu-item index="AdminAboutIntroduction" :route="{path: '/admin/about/introduction'}" v-if="showMenu('AdminAboutIntroduction')">公司介绍</el-menu-item>
						<el-menu-item index="AdminRecruitmentIndex" :route="{path: '/admin/about/recruitment'}" v-if="showMenu('AdminRecruitmentIndex')">招贤纳士</el-menu-item>
						<el-menu-item index="AdminNewsIndex" :route="{path: '/admin/about/news'}" v-if="showMenu('AdminNewsIndex')">公司动态</el-menu-item>
						<el-menu-item index="AdminChipAgentIndex" :route="{path: '/admin/about/chip_agent'}" v-if="showMenu('AdminChipAgentIndex')">芯片代理</el-menu-item>
						<el-menu-item index="AdminCooperativePartnerIndex" :route="{path: '/admin/about/cooperative_partner'}" v-if="showMenu('AdminCooperativePartnerIndex')">合作伙伴</el-menu-item>
						<el-menu-item index="AdminCompanyHonorIndex" :route="{path: '/admin/about/company_honor'}" v-if="showMenu('AdminCompanyHonorIndex')">公司荣誉</el-menu-item>
						<el-menu-item index="AdminContactUsIndex" :route="{path: '/admin/about/contact_us'}" v-if="showMenu('AdminContactUsIndex')">联系我们</el-menu-item>
					</el-submenu>
					<el-submenu index="AdminUser"  v-if="showMenu('AdminUser')" >
						<template slot="title">
							<i :class="[{ active: this.$route.meta.asideMenu === 'AdminUser' }, 'admin-aside-menu-icon admin-aside-menu-icon_user']"/>
							<span>会员管理</span>
						</template>
						<el-menu-item index="AdminFunctionIndex" :route="{path: '/admin/function'}" v-if="showMenu('AdminFunctionIndex')" >功能管理</el-menu-item>
						<el-menu-item index="AdminRoleIndex" :route="{path: '/admin/role'}" v-if="showMenu('AdminRoleIndex')" >角色管理</el-menu-item>
						<el-menu-item index="AdminMemberAdmin" :route="{path: '/admin/member/admin'}" v-if="showMenu('AdminMemberAdmin')" >管理员管理</el-menu-item>
						<el-menu-item index="AdminMember" :route="{path: '/admin/member/index'}" v-if="showMenu('AdminMember')" >会员管理</el-menu-item>
					</el-submenu>
					<el-submenu index="AdminSetting" v-if="showMenu('AdminSetting')">
						<template slot="title">
							<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSetting' }, 'admin-aside-menu-icon admin-aside-menu-icon_setting']"/>
							<span>基础数据配置</span>
						</template>
						<el-menu-item index="AdminAccessLabel" :route="{path: '/admin/setting/access_label'}" v-if="showMenu('AdminAccessLabel')">文档等级配置</el-menu-item>
						<el-menu-item index="AdminInterestProducts" :route="{path: '/admin/setting/interest_products'}" v-if="showMenu('AdminInterestProducts')">产品咨询配置</el-menu-item>
						<el-menu-item index="AdminNewsType" :route="{path: '/admin/setting/news_type'}" v-if="showMenu('AdminNewsType')">公司动态配置</el-menu-item>
						<el-menu-item index="AdminSettingRecruitment" :route="{path: '/admin/setting/recruitment'}" v-if="showMenu('AdminSettingRecruitment')">招贤纳士配置</el-menu-item>
						<el-menu-item index="AdminSettingSocialMedia" :route="{path: '/admin/setting/social_media'}" v-if="showMenu('AdminSettingSocialMedia')">社交媒体配置</el-menu-item>
						<el-menu-item index="AdminSettingReceiveEmail" :route="{path: '/admin/setting/receive_email'}" v-if="showMenu('AdminSettingReceiveEmail')">邮件收件人配置</el-menu-item>
						<el-menu-item index="AdminMetaDescription" :route="{path: '/admin/setting/meta_description'}" v-if="showMenu('AdminMetaDescription')">SEO优化配置</el-menu-item>
            <el-menu-item index="AdminCentecCulture" :route="{path: '/admin/setting/centec_culture'}" v-if="showMenu('AdminCentecCulture')">首页盛科文化配置</el-menu-item>
					</el-submenu>
					<el-menu-item index="AdminAccessLog" :route="{path: '/admin/access_log'}" v-if="showMenu('AdminAccessLog')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminAccessLog' }, 'admin-aside-menu-icon admin-aside-menu-icon_access_log']"/>
						<span slot="title">访问日志</span>
					</el-menu-item>
					<el-menu-item index="AdminNewsletter" :route="{path: '/admin/newsletter'}" v-if="showMenu('AdminNewsletter')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminNewsletter' }, 'admin-aside-menu-icon admin-aside-menu-icon_newsletter']"/>
						<span slot="title">Newsletter</span>
					</el-menu-item>
					<el-menu-item index="AdminSendMail" :route="{path: '/admin/send_mail/index'}" v-if="showMenu('AdminNewsletter')">
						<i :class="[{ active: this.$route.meta.asideMenu === 'SendMailIndex' }, 'admin-aside-menu-icon admin-aside-menu-icon_newsletter']"/>
						<span slot="title">订阅邮件</span>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main class="admin-main">
				<transition name="fade-transform" mode="out-in">
					<router-view :key="key"/>
				</transition>
			</el-main>
		</el-container>
		<el-footer class="admin-footer">
			<a class="copyright-link" href="http://beian.miit.gov.cn/" target="_blank">{{ $t('footer.copyright') + (record_number == null ? '' : record_number )}}</a>
		</el-footer>

		<el-drawer size="50%" title="盛科通信" direction="ltr" :visible.sync="drawer" :with-header="false" custom-class="mobile-menu hidden-sm-and-up">
			<el-menu :default-active="this.$route.meta.asideMenu" class="admin-aside-menu" :unique-opened="true" :router="true">
				<el-menu-item index="AdminIndex" :route="{path: '/admin/index'}" >
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminIndex' }, 'admin-aside-menu-icon admin-aside-menu-icon_index']"/>
					<span slot="title">网络后台</span>
				</el-menu-item>
				<el-menu-item index="AdminCarousel"  :route="{path: '/admin/carousel/index'}" v-if="showMenu('AdminCarousel')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminCarousel' }, 'admin-aside-menu-icon admin-aside-menu-icon_carousel']"/>
					<span slot="title">轮播管理</span>
				</el-menu-item>

				<el-menu-item index="AdminSilicon" :route="{path: '/admin/silicon/index'}" v-if="showMenu('AdminSilicon')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSilicon' }, 'admin-aside-menu-icon admin-aside-menu-icon_silicon']"/>
					<span slot="title">芯片管理</span>
				</el-menu-item>

				<el-menu-item index="AdminSoftware" :route="{path: '/admin/software/index'}" v-if="showMenu('AdminSoftware')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSoftware' }, 'admin-aside-menu-icon admin-aside-menu-icon_software']"/>
					<span slot="title">软件管理</span>
				</el-menu-item>

				<el-menu-item index="AdminSolution" :route="{path: '/admin/solution/index'}" v-if="showMenu('AdminSolution')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSolution' }, 'admin-aside-menu-icon admin-aside-menu-icon_solution']"/>
					<span slot="title">解决方案管理</span>
				</el-menu-item>
				<el-submenu index="TechnicalSupport" v-if="showMenu('TechnicalSupport')">
					<template slot="title">
						<i :class="[{ active: this.$route.meta.asideMenu === 'TechnicalSupport' }, 'admin-aside-menu-icon admin-aside-menu-icon_support']"/>
						<span>技术支持</span>
					</template>
					<el-menu-item index="AdminDocument" :route="{path: '/admin/document'}" v-if="showMenu('AdminDocument')">文档管理</el-menu-item>
					<el-menu-item index="DownloadHistoryIndex" :route="{path: '/admin/technical/download_history/index'}" v-if="showMenu('DownloadHistoryIndex')">下载历史</el-menu-item>
					<el-menu-item index="CommonProblemIndex" :route="{path: '/admin/technical/common_problem'}" v-if="showMenu('CommonProblemIndex')">常见问题</el-menu-item>
					<el-menu-item index="ProductConsultationIndex" :route="{path: '/admin/technical/product_consultation'}" v-if="showMenu('ProductConsultationIndex')">产品咨询</el-menu-item>
				</el-submenu>
				<el-submenu index="AdminAbout" v-if="showMenu('AdminAbout')">
					<template slot="title">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminAbout' }, 'admin-aside-menu-icon admin-aside-menu-icon_about']"/>
						<span>关于盛科</span>
					</template>
					<el-menu-item index="AdminAboutIntroduction" :route="{path: '/admin/about/introduction'}" v-if="showMenu('AdminAboutIntroduction')">公司介绍</el-menu-item>
					<el-menu-item index="AdminRecruitmentIndex" :route="{path: '/admin/about/recruitment'}" v-if="showMenu('AdminRecruitmentIndex')">招贤纳士</el-menu-item>
					<el-menu-item index="AdminNewsIndex" :route="{path: '/admin/about/news'}" v-if="showMenu('AdminNewsIndex')">公司动态</el-menu-item>
					<el-menu-item index="AdminChipAgentIndex" :route="{path: '/admin/about/chip_agent'}" v-if="showMenu('AdminChipAgentIndex')">芯片代理</el-menu-item>
					<el-menu-item index="AdminCooperativePartnerIndex" :route="{path: '/admin/about/cooperative_partner'}" v-if="showMenu('AdminCooperativePartnerIndex')">合作伙伴</el-menu-item>
					<el-menu-item index="AdminCompanyHonorIndex" :route="{path: '/admin/about/company_honor'}" v-if="showMenu('AdminCompanyHonorIndex')">公司荣誉</el-menu-item>
					<el-menu-item index="AdminContactUsIndex" :route="{path: '/admin/about/contact_us'}" v-if="showMenu('AdminContactUsIndex')">联系我们</el-menu-item>
				</el-submenu>
				<el-submenu index="AdminUser"  v-if="showMenu('AdminUser')" >
					<template slot="title">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminUser' }, 'admin-aside-menu-icon admin-aside-menu-icon_user']"/>
						<span>会员管理</span>
					</template>
					<el-menu-item index="AdminFunctionIndex" :route="{path: '/admin/function'}" v-if="showMenu('AdminFunctionIndex')" >功能管理</el-menu-item>
					<el-menu-item index="AdminRoleIndex" :route="{path: '/admin/role'}" v-if="showMenu('AdminRoleIndex')" >角色管理</el-menu-item>
					<el-menu-item index="AdminMemberAdmin" :route="{path: '/admin/member/admin'}" v-if="showMenu('AdminMemberAdmin')" >管理员管理</el-menu-item>
					<el-menu-item index="AdminMember" :route="{path: '/admin/member/index'}" v-if="showMenu('AdminMember')" >会员管理</el-menu-item>
				</el-submenu>
				<el-submenu index="AdminSetting" v-if="showMenu('AdminSetting')">
					<template slot="title">
						<i :class="[{ active: this.$route.meta.asideMenu === 'AdminSetting' }, 'admin-aside-menu-icon admin-aside-menu-icon_user']"/>
						<span>基础数据配置</span>
					</template>
					<el-menu-item index="AdminAccessLabel" :route="{path: '/admin/setting/access_label'}" v-if="showMenu('AdminAccessLabel')">文档等级配置</el-menu-item>
					<el-menu-item index="AdminInterestProducts" :route="{path: '/admin/setting/interest_products'}" v-if="showMenu('AdminInterestProducts')">产品咨询配置</el-menu-item>
					<el-menu-item index="AdminNewsType" :route="{path: '/admin/setting/news_type'}" v-if="showMenu('AdminNewsType')">公司动态配置</el-menu-item>
					<el-menu-item index="AdminSettingRecruitment" :route="{path: '/admin/setting/recruitment'}" v-if="showMenu('AdminSettingRecruitment')">招贤纳士配置</el-menu-item>
					<el-menu-item index="AdminSettingSocialMedia" :route="{path: '/admin/setting/social_media'}" v-if="showMenu('AdminSettingSocialMedia')">社交媒体配置</el-menu-item>
					<el-menu-item index="AdminSettingReceiveEmail" :route="{path: '/admin/setting/receive_email'}" v-if="showMenu('AdminSettingReceiveEmail')">邮件收件人配置</el-menu-item>
				</el-submenu>
				<el-menu-item index="AdminAccessLog" :route="{path: '/admin/access_log'}" v-if="showMenu('AdminAccessLog')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminAccessLog' }, 'admin-aside-menu-icon admin-aside-menu-icon_access_log']"/>
					<span slot="title">访问日志</span>
				</el-menu-item>
				<el-menu-item index="AdminNewsletter" :route="{path: '/admin/newsletter'}" v-if="showMenu('AdminNewsletter')">
					<i :class="[{ active: this.$route.meta.asideMenu === 'AdminNewsletter' }, 'admin-aside-menu-icon admin-aside-menu-icon_newsletter']"/>
					<span slot="title">Newsletter</span>
				</el-menu-item>
			</el-menu>
		</el-drawer>
	</el-container>
</template>

<script>
	export default {
		name: 'AdminLayout',
		data() {
			return {
				isCollapse: false,
				asideWidth: '200px',
				drawer: false,
				keywords: null,
				record_number: null
			}
		},
		computed: {
			key() {
				return this.$route.fullPath
			},
			showMenu(){
				return menu => {
					return this.$store.getters.checkMenuList(menu)
				}
			}
		},
		mounted() {
			this.getZip()
		},
		methods: {
			handleAsideCollapse() {
				this.isCollapse = !this.isCollapse
				this.asideWidth = this.isCollapse ? '64px' : '200px'
			},
			logout(){
				this.$store.commit('logout')
				this.$router.push({path: '/admin/login'})
			},
			changePassword() {
				this.$router.push({path: '/admin/password/'})
			},
			getZip() {
				let _this = this
				Http.get(`/api/v1/recruitments/get_job_email`).then(result => {
					if (result?.code === 0) {
						if (result.data) {
							_this.record_number = result.data.record_number
						}
					}
				}, error => {
					console.info(error)
				})
			},
		}
	}
</script>

<style lang="scss">
	
	.admin-header {
		i {
			color: #fff !important;
		}
		.el-menu-nav-popper .el-menu .el-menu-item {
			width: 100% !important;
		}
		
		.el-menu-item {
			&:hover {
				background: #3a7dc5 !important;
				color: #fff !important;
			}
		}

		.el-submenu__title {
			color: #fff !important;
		}

		.search {
			input {
				background: #3a7dc5;
				color: #fff;
				border: none;

				&::placeholder {
					color: #fff;
				}
			}
		}

		.office-website, .user {
			float: right;
		}
	}
</style>
<style scoped lang="scss">
	.admin-aside-menu {
		border-right: none;

		.admin-aside-menu-icon {
			width: 24px;
			height: 18px;
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			&.admin-aside-menu-icon_index {
				background-image: url("../../assets/images/admin_side_menu_icon_index.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_index_active.png");
				}
			}
			&.admin-aside-menu-icon_carousel {
				background-image: url("../../assets/images/admin_side_menu_icon_carousel.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_carousel_active.png");
				}
			}
			&.admin-aside-menu-icon_silicon {
				background-image: url("../../assets/images/admin_side_menu_icon_silicon.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_silicon_active.png");
				}
			}
			&.admin-aside-menu-icon_software {
				background-image: url("../../assets/images/admin_side_menu_icon_software.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_software_active.png");
				}
			}
			&.admin-aside-menu-icon_solution {
				background-image: url("../../assets/images/admin_side_menu_icon_solution.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_solution_active.png");
				}
			}
			&.admin-aside-menu-icon_support {
				background-image: url("../../assets/images/admin_side_menu_icon_support.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_support_active.png");
				}
			}
			&.admin-aside-menu-icon_user {
				background-image: url("../../assets/images/admin_side_menu_icon_user.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_user_active.png");
				}
			}
			&.admin-aside-menu-icon_document {
				background-image: url("../../assets/images/admin_side_menu_icon_document.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_document_active.png");
				}
			}

			&.admin-aside-menu-icon_about {
				background-image: url("../../assets/images/admin_side_menu_icon_about.png");

				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_about_active.png");
				}
			}
			&.admin-aside-menu-icon_setting {
				background-image: url("../../assets/images/admin_side_menu_icon_setting.png");
				
				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_setting_active.png");
				}
			}
			&.admin-aside-menu-icon_access_log {
				background-image: url("../../assets/images/admin_side_menu_icon_access_log.png");
				
				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_access_log_active.png");
				}
			}
			&.admin-aside-menu-icon_newsletter {
				background-image: url("../../assets/images/admin_side_menu_icon_newsletter.png");
				
				&.active {
					background-image: url("../../assets/images/admin_side_menu_icon_newsletter_active.png");
				}
			}

		}
	}

	.admin-header {
		padding: 0;

		.admin-header-menu {
			.pc-logo {
				display: flex;
				width: 200px;
				justify-content: space-between;
				justify-items: center;
				align-items: center;
				align-content: center;
				background: #3A7DC5 !important;
				padding: 0 10px;

				img {
					display: inline-flex;
					height: 30px;
					width: auto;
				}
			}
		}
	}

	.admin-main {
		background: #FAFBFD;
		min-height: calc(100vh - 120px);
		padding: 1rem;
	}

	.admin-footer {
		border-top: 1px solid #e6e6e6;
		background: #fff;
		color: #9A9A9A;
		text-align: center;
		padding: 1rem;
		display: flex;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: center;
		font-size: .8rem;
		line-height: 1.5;

		.copyright-link {
			color: rgb(154, 154, 154);

			&:hover {
				text-decoration: none;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.admin-header {
			.admin-header-menu {
				.mobile-logo {
					display: block;
					background: #3a7dc5 !important;
					color: #fff !important;
					padding: 0 10px;

					img {
						display: inline-flex;
						height: 20px;
						width: auto;
					}
				}
			}

		}

	}
</style>