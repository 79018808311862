<template>
    <div class="CentecTitleIllustration">
        <img v-if="backgroundImage" :src="backgroundImage" class="illustration-image" :alt="title">
        <div class="content" >
            <p v-text="title" class="title"/>
            <p v-if="slogan" v-text="slogan" class="slogan"/>
        </div>
    </div>
</template>
<style scoped lang="scss">
    .CentecTitleIllustration{
        position: relative;
        .illustration-image{
            display: block;
            width: 100%;
            height: auto;
        }
        .content{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            justify-items: center;
            align-content: flex-start;
            align-items: flex-start;
            padding-left: 5%;
            flex-direction: column;
            box-sizing: border-box;
            .title{
                color: #fff;
                font-size: 30px;
                line-height: 50px;

            }
            .slogan{
                color: #fff;
                font-size: 16px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .CentecTitleIllustration{
            .content{
                .title{
                    font-size: 18px;
                    line-height: 40px;
                }
                .slogan{
                    font-size: 14px;
                }
            }
        }
    }
</style>
<script>
    import defaultBackgroundImage from '../assets/images/page_title_illustration_about_us.png'
    export default {
        name: 'CentecTitleIllustration',
        props: {
            backgroundImage: {
                type: String,
                default: defaultBackgroundImage
            },
            title: String,
            slogan: String
        },
    }
</script>
