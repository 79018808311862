<template>
    <section class="frontend">
        <centec-header v-if="settings.header"/>
        <main>
            <transition name="fade-transform" mode="out-in">
              <router-view :key="key" v-on:updateSettings="updateSettings" />
            </transition>
        </main>
        <centec-footer v-if="settings.footer"/>
        <el-backtop target="body" style="color: #3A7DC5;"></el-backtop>
    </section>
</template>

<script>
    import CentecHeader from '../../components/CentecHeader'
    import CentecFooter from '../../components/CentecFooter'
    export default {
        name: 'FrontendLayout',
        data() {
            return {
                settings: {
                    header: true,
                    footer: true
                }
            }
        },
        components: {
            CentecHeader,
            CentecFooter
        },
        computed: {
            key () {
                return this.$route.fullPath
            }
        },
        beforeCreate() {
            let lang = localStorage.getItem('CentecLanguage')
            if (lang === undefined || lang == null) {
                lang = navigator.language||navigator.userLanguage
                lang = lang.substr(0, 2)
                if(lang !== 'zh'){
                    this.$store.commit('changeLanguage', 'en')
                    window.location.reload()
                }
                else {
                    this.$store.commit('changeLanguage', 'cn')
                    // window.location.reload()
                }
            }
        },
        mounted() {
            Http.get('/api/v1/get_contact').then(result => {
                if (result?.code === 0 && result?.data && result.data.cnzz_key) {
                    const script = document.createElement('script')
                    script.src = `https://v1.cnzz.com/z_stat.php?id=${result.data.cnzz_key}&web_id=${result.data.cnzz_key}`
                    script.language = 'JavaScript'
                    document.body.appendChild(script)
                }
            }, () => {
            })
            
        },
        watch: {
            '$route': {
                handler(to, from) {
                    setTimeout(() => { //避免首次获取不到window._czc
                        if (window._czc) {
                            let location = window.location;
                            let contentUrl = location.pathname + location.hash;
                            let refererUrl = '/';
                            // 用于发送某个URL的PV统计请求，
                            window._czc.push(['_trackPageview', contentUrl, refererUrl])
                        }
                    }, 300)
                },
                immediate: true  // 首次进入页面即执行
            }
        },
        methods: {
            updateSettings(settings) {
                for (let key in settings) {
                    this.settings[key] = settings[key]
                }
            }
        }
    }
</script>

<style lang="scss">
    .frontend{
        .el-breadcrumb {
            margin: 1rem auto;
            font-size: 0.9rem;
        }
    }
</style>