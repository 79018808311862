<template>
	<no-ssr>
		<mavon-editor defaultOpen="edit" :subfield="false" @change="changeData" ref='md' v-model="mdText" placeholder=" " :ishljs="true" @imgAdd="$imgAdd" :autofocus="false" :externalLink="externalLink"/>
	</no-ssr>
</template>

<script>
	import NoSSR from 'vue-no-ssr'
  import MarkDownCss from '../assets/editor/markdown/github-markdown.min.css'
  import katexCss from '../assets/editor/katex/katex.min.css'
	export default {
		name: "CentecEditor",
		components: {
			'no-ssr': NoSSR
		},
		data() {
			return {
				previewContent: '',
        externalLink: {
          markdown_css: function () {
            // 这是你的markdown css文件路径
            return MarkDownCss
          },
          hljs_js: function () {
            // 这是你的hljs文件路径
            return '../assets/editor/highlightjs/highlight.min'
          },
          katex_css: function () {
            // 这是你的katex配色方案路径路径
            return katexCss
          },
          katex_js: function () {
            // 这是你的katex.js路径
            return '../assets/editor/katex/katex.min'
          }
        }
			}
		},
		props: {
			"content": String
		},
		computed: {
			mdText: {
				get(){
					return this.$props.content || ''
				},
				set(val){
					this.$emit('onChange', val)
				}
			}
		},
		methods: {
			$imgAdd(pos, $file) {
				// 第一步.将图片上传到服务器.
				let formData = new FormData()
				formData.append('model', "MavonEditor")
				formData.append('file', $file)
				Http.post('/api/v1/admin/attachments/upload', formData, {'Content-Type': 'multipart/form-data'}).then(result => {
					// 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
					// $vm.$img2Url 详情见本页末尾
					if (result.code === 0 && result?.data?.path) {
						this.$refs.md.$img2Url(pos, result?.data?.path)
					}
				})
			},
			changeData(value,render) {
				this.previewContent = render;
				this.send()
			},
			send() {
				this.$emit('func',this.previewContent)
			}
		}
	}
</script>

<style lang="scss">
  @import url("../assets/editor/highlightjs/styles/github.min.css");
	.markdown-body{
		max-height: 600px;
		overflow-y: auto;
		z-index: unset !important;
	}
	.v-note-op{
		z-index: unset !important;
	}
</style>