import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import FrontendLayout from './views/layout/FrontendLayout'
import AdminLayout from './views/layout/AdminLayout'
import store from 'store.js'

Vue.use(Router)

// fix vue-router点击菜单栏同一个模块报错： Uncaught (in promise) NavigationDuplicated {_name:""NavigationDuplicated"
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 轮播管理
const AdminCarouselRouter = {
    path: '/admin/carousel',
    name: 'AdminCarousel',
    redirect: '/admin/carousel/index',
    component: AdminLayout,
    children: [
        {
            path: 'index',
            name: 'AdminCarouselIndex',
            component: () => import('./views/admin/carousel/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCarousel' }
        }, {
            path: 'new',
            name: 'AdminCarouselNew',
            component: () => import('./views/admin/carousel/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCarousel' }
        }, {
            path: 'edit/:id',
            name: 'AdminCarouselEdit',
            component: () => import('./views/admin/carousel/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCarousel' }
        }
    ]
}
// 芯片管理
const AdminSiliconRouter = {
    path: '/admin/silicon',
    name: 'AdminSilicon',
    redirect: '/admin/silicon/index',
    component: AdminLayout,
    children: [
        {
            path: 'category',
            name: 'AdminSiliconCategory',
            component: () => import('./views/admin/silicon/category/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        },
        {
            path: 'category/edit/:id',
            name: 'AdminSiliconCategoryEdit',
            component: () => import('./views/admin/silicon/category/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        },
        {
            path: 'category/new',
            name: 'AdminSiliconCategoryNew',
            component: () => import('./views/admin/silicon/category/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        },
        {
            path: 'index',
            name: 'AdminSiliconIndex',
            component: () => import('./views/admin/silicon/silicon/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        },
        {
            path: 'new',
            name: 'AdminSiliconNew',
            component: () => import('./views/admin/silicon/silicon/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        },
        {
            path: 'edit/:id',
            name: 'AdminSiliconEdit',
            component: () => import('./views/admin/silicon/silicon/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSilicon' }
        }
    ]
}
// 软件管理
const AdminSoftwareRouter = {
    path: '/admin/software',
    name: 'AdminSoftware',
    redirect: '/admin/software/index',
    component: AdminLayout,
    children: [
        {
            path: 'category',
            name: 'AdminSoftwareCategory',
            component: () => import('./views/admin/software/category/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        },
        {
            path: 'category/edit/:id',
            name: 'AdminSoftwareCategoryEdit',
            component: () => import('./views/admin/software/category/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        },
        {
            path: 'category/new',
            name: 'AdminSoftwareCategoryNew',
            component: () => import('./views/admin/software/category/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        },
        {
            path: 'index',
            name: 'AdminSoftwareIndex',
            component: () => import('./views/admin/software/software/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        },
        {
            path: 'new',
            name: 'AdminSoftwareNew',
            component: () => import('./views/admin/software/software/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        },
        {
            path: 'edit/:id',
            name: 'AdminSoftwareEdit',
            component: () => import('./views/admin/software/software/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSoftware' }
        }
    ]
}
// 解决方案
const AdminSolutionRouter = {
    path: '/admin/solution',
    name: 'AdminSolution',
    redirect: '/admin/solution/index',
    component: AdminLayout,
    children: [
        {
            path: 'category',
            name: 'AdminSolutionCategory',
            component: () => import('./views/admin/solution/category/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        },
        {
            path: 'category/edit/:id',
            name: 'AdminSolutionCategoryEdit',
            component: () => import('./views/admin/solution/category/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        },
        {
            path: 'category/new',
            name: 'AdminSolutionCategoryNew',
            component: () => import('./views/admin/solution/category/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        },
        {
            path: 'index',
            name: 'AdminSolutionIndex',
            component: () => import('./views/admin/solution/solution/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        },
        {
            path: 'new',
            name: 'AdminSolutionNew',
            component: () => import('./views/admin/solution/solution/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        },
        {
            path: 'edit/:id',
            name: 'AdminSolutionEdit',
            component: () => import('./views/admin/solution/solution/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSolution' }
        }
    ]
}
// 技术支持
const TechnicalSupportRouter = {
    path: '/admin/technical',
    component: AdminLayout,
    children: [
        {
            path: 'product_consultation',
            name: 'ProductConsultation',
            component: () => import('./views/admin/technical/product/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'ProductConsultationIndex' }
        },
        {
            path: 'product_consultation/edit/:id',
            name: 'ProductConsultationEdit',
            component: () => import('./views/admin/technical/product/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'ProductConsultationIndex' }
        },
        {
            path: 'common_problem',
            name: 'CommonProblem',
            component: () => import('./views/admin/technical/problem/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'CommonProblemIndex' }
        },
        {
            path: 'common_problem/new',
            name: 'CommonProblemNew',
            component: () => import('./views/admin/technical/problem/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'CommonProblemIndex' }
        },
        {
            path: 'common_problem/edit/:id',
            name: 'CommonProblemEdit',
            component: () => import('./views/admin/technical/problem/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'CommonProblemIndex' }
        },
        {
            path: 'download_history/index/:username',
            name: 'DownloadHistoryIndexUsername',
            component: () => import('./views/admin/technical/history/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'DownloadHistoryIndex' }
        },
        {
            path: 'download_history/index/',
            name: 'DownloadHistoryIndex',
            component: () => import('./views/admin/technical/history/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'DownloadHistoryIndex' }
        },
    ]
}
const SendMailRouter = {
    path: '/admin/send_mail',
    redirect: '/admin/send_mail/index',
    component: AdminLayout,
    children: [
        {
            path: 'index',
            name: 'AdminSendMailIndex',
            component: () => import('./views/admin/send_mail/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSendMail' }
        },
        {
            path: 'new',
            name: 'AdminSendMailNew',
            component: () => import('./views/admin/send_mail/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSendMail' }
        },
        {
            path: 'show/:id',
            name: 'AdminSendMailShow',
            component: () => import('./views/admin/send_mail/Show.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSendMail' }
        },
    
    ]
}
// 文档管理
const AdminDocumentRouter = {
    path: '/admin/document',
    component: AdminLayout,
    children: [
        {
            path: '/',
            name: 'AdminDocument',
            component: () => import('./views/admin/document/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminDocument' }
        },
        {
            path: 'show/:id',
            name: 'AdminDocumentShow',
            component: () => import('./views/admin/document/Show.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminDocument' }
        },
        {
            path: 'edit/:id',
            name: 'AdminDocumentEdit',
            component: () => import('./views/admin/document/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminDocument' }
        },
        {
            path: 'new',
            name: 'AdminDocumentNew',
            component: () => import('./views/admin/document/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminDocument' }
        }
    ]
}
// 关于盛科
const AdminAboutRouter = {
    path: '/admin/about',
    component: AdminLayout,
    children: [
        {
            path: 'introduction',
            name: 'AdminAboutIntroduction',
            component: () => import('./views/admin/about/introduction/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminAboutIntroduction' }
        }, {
            path: 'recruitment',
            name: 'AdminRecruitmentIndex',
            component: () => import('./views/admin/about/recruitment/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRecruitmentIndex' }
        }, {
            path: 'recruitment/new',
            name: 'AdminRecruitmentNew',
            component: () => import('./views/admin/about/recruitment/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRecruitmentIndex' }
        }, {
            path: 'recruitment/edit/:id',
            name: 'AdminRecruitmentEdit',
            component: () => import('./views/admin/about/recruitment/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRecruitmentIndex' }
        }, {
            path: 'chip_agent',
            name: 'AdminChipAgentIndex',
            component: () => import('./views/admin/about/chip_agent/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminChipAgentIndex' }
        }, {
            path: 'chip_agent/new',
            name: 'AdminChipAgentNew',
            component: () => import('./views/admin/about/chip_agent/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminChipAgentIndex' }
        }, {
            path: 'chip_agent/edit/:id',
            name: 'AdminChipAgentEdit',
            component: () => import('./views/admin/about/chip_agent/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminChipAgentIndex' }
        }, {
            path: 'news',
            name: 'AdminNewsIndex',
            component: () => import('./views/admin/about/news/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminNewsIndex' }
        }, {
            path: 'news/new',
            name: 'AdminNewsNew',
            component: () => import('./views/admin/about/news/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminNewsIndex' }
        }, {
            path: 'news/edit/:id',
            name: 'AdminNewsEdit',
            component: () => import('./views/admin/about/news/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminNewsIndex' }
        }, {
            path: 'cooperative_partner',
            name: 'AdminCooperativePartnerIndex',
            component: () => import('./views/admin/about/cooperative_partner/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCooperativePartnerIndex' }
        }, {
            path: 'cooperative_partner/new',
            name: 'AdminCooperativePartnerNew',
            component: () => import('./views/admin/about/cooperative_partner/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCooperativePartnerIndex' }
        }, {
            path: 'cooperative_partner/edit/:id',
            name: 'AdminCooperativePartnerEdit',
            component: () => import('./views/admin/about/cooperative_partner/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCooperativePartnerIndex' }
        }, {
            path: 'company_honor',
            name: 'AdminCompanyHonorIndex',
            component: () => import('./views/admin/about/company_honor/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCompanyHonorIndex' }
        }, {
            path: 'company_honor/new',
            name: 'AdminCompanyHonorNew',
            component: () => import('./views/admin/about/company_honor/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCompanyHonorIndex' }
        }, {
            path: 'company_honor/edit/:id',
            name: 'AdminCompanyHonorEdit',
            component: () => import('./views/admin/about/company_honor/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCompanyHonorIndex' }
        },
        {
            path: 'contact_us',
            name: 'AdminContactUsIndex',
            component: () => import('./views/admin/about/contact_us/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCompanyHonorIndex' }
        }
    ]
}
// 功能管理
const AdminFunctionRouter = {
    path: '/admin/function',
    component: AdminLayout,
    children: [
        {
            path: '/',
            name: 'AdminFunctionIndex',
            component: () => import('./views/admin/function/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminFunctionIndex' }
        }, {
            path: 'new',
            name: 'AdminFunctionNew',
            component: () => import('./views/admin/function/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminFunctionIndex' }
        }, {
            path: 'edit/:id',
            name: 'AdminFunctionEdit',
            component: () => import('./views/admin/function/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminFunctionIndex' }
        }
    ]
}
// 角色管理
const AdminRoleRouter = {
    path: '/admin/role',
    component: AdminLayout,
    children: [
        {
            path: '/',
            name: 'AdminRoleIndex',
            component: () => import('./views/admin/role/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRoleIndex' }
        }, {
            path: 'new',
            name: 'AdminRoleNew',
            component: () => import('./views/admin/role/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRoleIndex' }
        }, {
            path: 'edit/:id',
            name: 'AdminRoleEdit',
            component: () => import('./views/admin/role/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminRoleIndex' }
        }
    ]
}
//账号管理
const MemberRouter = {
    path: '/admin/member',
    component: AdminLayout,
    children: [
        {
            path: 'index',
            name: 'MemberIndex',
            component: () => import('./views/admin/member/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMember' }
        },
        {
            path: 'new',
            name: 'MemberNew',
            component: () => import('./views/admin/member/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMember' }
        },
        {
            path: 'edit/:id',
            name: 'MemberEdit',
            component: () => import('./views/admin/member/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMember' }
        },
        {
            path: 'show/:id',
            name: 'MemberShow',
            component: () => import('./views/admin/member/Show.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMember' }
        },
        {
            path: 'admin',
            name: 'AdminMemberAdminIndex',
            component: () => import('./views/admin/member_admin/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMemberAdmin' }
        },
        {
            path: 'admin/new',
            name: 'AdminMemberAdminNew',
            component: () => import('./views/admin/member_admin/New.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMemberAdmin' }
        },
        {
            path: 'admin/edit/:id',
            name: 'AdminMemberAdminEdit',
            component: () => import('./views/admin/member_admin/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMemberAdmin' }
        }
    ]
}


const SettingRouter = {
    path: '/admin/setting/',
    name: 'AdminSetting',
    redirect: '/admin/setting/interest_products',
    component: AdminLayout,
    children: [
        {
            path: 'access_label',
            name: 'AdminAccessLabel',
            component: () => import('./views/admin/setting/access_label/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminAccessLabel' }
        },
        {
            path: 'interest_products',
            name: 'AdminInterestProducts',
            component: () => import('./views/admin/setting/interest_products/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminInterestProducts' }
        },
        {
            path: 'news_type',
            name: 'AdminNewsType',
            component: () => import('./views/admin/setting/news/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminNewsType' }
        },
        {
            path: 'recruitment',
            name: 'AdminSettingRecruitment',
            component: () => import('./views/admin/setting/recruitment/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSettingRecruitment' }
        },
        {
            path: 'social_media',
            name: 'AdminSettingSocialMedia',
            component: () => import('./views/admin/setting/social_media/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSettingSocialMedia' }
        },
        {
            path: 'receive_email',
            name: 'AdminSettingReceiveEmail',
            component: () => import('./views/admin/setting/receive_email/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminSettingReceiveEmail' }
        },
        {
            path: 'meta_description',
            name: 'AdminMetaDescription',
            component: () => import('./views/admin/setting/meta_description/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminMetaDescription' }
        },
        {
            path: 'centec_culture',
            name: 'AdminCentecCulture',
            component: () => import('./views/admin/setting/centec_culture/Index.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminCentecCulture' }
        }
    ]
}

const PasswordRouter = {
    path: '/admin/password/',
    name: 'AdminPassword',
    redirect: '/admin/password/edit/',
    component: AdminLayout,
    children: [
        {
            path: 'edit/',
            name: 'AdminPasswordEdit',
            component: () => import('./views/admin/password/Edit.vue'),
            meta: { requiresAuth: true, asideMenu: 'AdminPassword' }
        }
    ]
}


const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '',
            redirect: '/',
            component: FrontendLayout,
            children: [
                {
                    path: '/',
                    name: 'FrontendIndex',
                    component: () => import('./views/frontend/index/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/about_us',
                    name: 'FrontendAboutUs',
                    component: () => import('./views/frontend/about/about_us/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/recruitment',
                    name: 'FrontendRecruitment',
                    component: () => import('./views/frontend/about/recruitment/Index.vue'),
                    meta: { title: '' }
                },
                {
                    path: '/branch',
                    name: 'FrontendChipAgent',
                    component: () => import('./views/frontend/about/chip_agent/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/cooperative_partner',
                    name: 'FrontendCooperativePartner',
                    component: () => import('./views/frontend/about/cooperative_partner/Index.vue'),
                    meta: { title: '' }
                },
                {
                    path: '/company_honor',
                    name: 'FrontendCompanyHonor',
                    component: () => import('./views/frontend/about/company_honor/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/news',
                    name: 'FrontendNews',
                    component: () => import('./views/frontend/about/news/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/news/:id',
                    name: 'FrontendNewsInfo',
                    component: () => import('./views/frontend/about/news/Info.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/compliance_statement',
                    name: 'FrontendComplianceStatement',
                    component: () => import('./views/frontend/about/compliance_statement/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/compliance_statement/:id',
                    name: 'FrontendComplianceStatementInfo',
                    component: () => import('./views/frontend/about/compliance_statement/Info.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/BasicInformation',
                    name: 'FrontendBasicInformation',
                    component: () => import('./views/frontend/personal/BasicInformation.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/SecurityCenter',
                    name: 'FrontendSecurityCenter',
                    component: () => import('./views/frontend/personal/SecurityCenter.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/UpdateInformation',
                    name: 'FrontendUpdateInformation',
                    component: () => import('./views/frontend/personal/UpdateInformation.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/TechnicalSupport/',
                    name: 'FrontendTechnicalSupport',
                    component: () => import('./views/frontend/technical/TechnicalSupport.vue'),
                    meta: { title: '', title_en: '' },
                },
                {
                    path: '/ProductConsultation',
                    name: 'FrontendProductConsultation',
                    component: () => import('./views/frontend/technical/ProductConsultation'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/ResourceDownloadDetail/:id',
                    name: 'FrontendResourceDownloadDetail',
                    component: () => import('./views/frontend/technical/ResourceDownloadDetail'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/DownloadList',
                    name: 'FrontendDownloadList',
                    component: () => import('./views/frontend/personal/DownloadList'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/CommonProblem',
                    name: 'FrontendCommonProblem',
                    component: () => import('./views/frontend/technical/CommonProblem'),
                    meta: { title: '', title_en: '' },
                    beforeEnter: (to, from, next) => {
                        window.open('http://feedback.centecnetworks.com', '_blank')
                        NProgress.done()
                        next(false)
                    }
                },
                {
                    path: '/Contact_us',
                    name: 'FrontendContactUs',
                    component: () => import('./views/frontend/about/contact_us/Index.vue'),
                    meta: { title: '', title_en: '' }
                },
                {
                    path: '/silicon/:id',
                    name: 'FrontendSiliconInfo',
                    component: () => import('./views/frontend/silicon/Info.vue'),
                    meta: { title: '芯片', title_en: 'Silicon' }
                },
                {
                    path: '/silicon/:category_keyword/:product_keyword',
                    name: 'FrontendSiliconInfoByKeyword',
                    component: () => import('./views/frontend/silicon/Info.vue'),
                    meta: { title: '芯片', title_en: 'Silicon' }
                },
                {
                    path: '/silicon',
                    name: 'FrontendSilicon',
                    component: () => import('./views/frontend/silicon/Index.vue'),
                    meta: { title: '芯片', title_en: 'Silicon' }
                },
                {
                    path: '/software/:id',
                    name: 'FrontendSoftwareInfo',
                    component: () => import('./views/frontend/software/Info.vue'),
                    meta: { title: '软件', title_en: 'Software' }
                },
                {
                    path: '/software/:category_keyword/:product_keyword',
                    name: 'FrontendSoftwareInfoByKeyword',
                    component: () => import('./views/frontend/software/Info.vue'),
                    meta: { title: '软件', title_en: 'Software' }
                },
                {
                    path: '/software',
                    name: 'FrontendSoftware',
                    component: () => import('./views/frontend/software/Index.vue'),
                    meta: { title: '软件', title_en: 'Software' }
                },
                {
                    path: '/solution/:id',
                    name: 'FrontendSolutionInfo',
                    component: () => import('./views/frontend/solution/Info.vue'),
                    meta: { title: '解决方案', title_en: 'Solutions' }
                },
                {
                    path: '/solution/:category_keyword/:product_keyword',
                    name: 'FrontendSolutionInfoByKeyword',
                    component: () => import('./views/frontend/solution/Info.vue'),
                    meta: { title: '解决方案', title_en: 'Solutions' }
                },
                {
                    path: '/solution',
                    name: 'FrontendSolution',
                    component: () => import('./views/frontend/solution/Index.vue'),
                    meta: { title: '解决方案', title_en: 'Solutions' }
                },
                {
                    path: '/ActivateUser',
                    name: 'FrontendActivateUser',
                    component: () => import('./views/frontend/personal/ActivateUser.vue'),
                    meta: { title: '用户激活', title_en: '' }
                },
                {
                    path: '/UserRegister',
                    name: 'FrontendUserRegister',
                    component: () => import('./views/frontend/personal/UserRegister.vue'),
                    meta: { title: '用户注册', title_en: '' }
                },
                {
                    path: '/Search',
                    name: 'FrontendSearch',
                    component: () => import('./views/frontend/search/Index.vue'),
                    meta: { title: '全站搜索', title_en: '' }
                }
            ]
        },
        {
            path: '/admin/login',
            name: 'AdminLogin',
            component: () => import('./views/admin/Login.vue'),
            meta: { requiresAuth: false }
        },
        {
            path: '/admin/forget_password',
            name: 'AdminForgetPassword',
            component: () => import('./views/admin/ForgetPassword.vue'),
            meta: { requiresAuth: false }
        },
        {
            path: '/admin',
            redirect: '/admin/index',
            component: AdminLayout,
            children: [
                {
                    path: '/admin/index',
                    name: 'AdminIndex',
                    component: () => import('./views/admin/index/Index.vue'),
                    meta: { requiresAuth: true, asideMenu: 'AdminIndex' }
                },
                {
                    path: '/admin/access_log',
                    name: 'AdminAccessLog',
                    component: () => import('./views/admin/access_log/Index.vue'),
                    meta: { requiresAuth: true, asideMenu: 'AdminAccessLog' }
                },
                {
                    path: '/admin/newsletter',
                    name: 'AdminNewsletter',
                    component: () => import('./views/admin/newsletter/Index.vue'),
                    meta: { requiresAuth: true, asideMenu: 'AdminNewsletter' }
                }
            ]
        },
        AdminFunctionRouter,
        AdminRoleRouter,
        AdminCarouselRouter,
        AdminDocumentRouter,
        AdminSiliconRouter,
        AdminSoftwareRouter,
        AdminSolutionRouter,
        AdminAboutRouter,
        TechnicalSupportRouter,
        MemberRouter,
        PasswordRouter,
        SettingRouter,
        SendMailRouter,
        {
            path: "*",
            component: () => import('./views/frontend/error/error.vue')
        }
    ]
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
        
        const language = store.state.language || 'cn'
    
        if (language === 'cn') {
            document.title = to.meta.title || "盛科通信"
        } else {
            document.title = to.meta.title_en || "centec communications"
        }
        NProgress.start()
        let token = store.getters.getCentecToken
        if (!!token) {
            if (to.name?.indexOf('Frontend') !== -1) {
                store.dispatch('getFrontendUserInfo').catch(e => {
                    store.commit('logout')
                })
            } else {
                store.dispatch('getInfo').catch(e => {
                    store.commit('logout')
                })
            }
            
        }
        
        // 若为后台登录页面， 则判断是否存在token，存在直接跳转 不存在进行登录
        if (to.name === 'AdminLogin') {
            if (!!token) {
                next({ path: to.params.toPath ? to.params.toPath : '/admin' })
            } else {
                next()
            }
        }
        
        // 其他页面 判断 meta 中 requiresAuth 是否为true
        // requiresAuth: true 校验用户是否登录
        if (!!to.meta.requiresAuth) {
            // 若token不存在则直接跳转登录页面
            if (!!token) {
                // 若token不存在则直接跳转登录页面
                store.dispatch('getInfo').then(() => {
                    // 校验用户的而全新啊
                    store.dispatch('getFunction').then(() => {
                        if (to.name === 'AdminIndex' || to.name === 'AdminPassword' || to.name === 'AdminPasswordEdit' || store.getters.checkMenuList(to.meta.asideMenu)) {
                            next()
                        } else {
                            next({ path: '/admin' })
                        }
                    }).catch(e => {
                        console.info(e)
                    })
                }, () => {
                    // 根据token请求用户信息失败则退出重新登录
                    next({ path: '/admin/login', params: { toPath: to.path } })
                })
                
            } else {
                next({ path: '/admin/login', params: { toPath: to.path } })
            }
        } else {
            next()
        }
    }
)

router.afterEach(transition => {
    NProgress.done()
})

export default router
