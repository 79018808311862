/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import '../assets/css/normal.css'
import '../assets/css/bootstrap-utilities.css'
import 'babel-polyfill'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import '../assets/css/index.css'
import 'default-passive-events'

import App from '../App.vue'
import router from '../router'
import store from '../store'
import i18n from '../i18n/i18n'
import UUID from 'vue-uuid'
import VueLazyload from 'vue-lazyload'
import Http from '@/utils/Http'

import CentecEditor from "../components/CentecEditor"
Vue.component('centec-editor', CentecEditor)

import CentecTitleIllustration from '../components/CentecTitleIllustration.vue'
Vue.component('centec-title-illustration', CentecTitleIllustration)

import CentecRightNavigation from '../components/CentecRightNavigation.vue'
Vue.component('centec-right-navigation', CentecRightNavigation)

// import VueAMap from 'vue-amap';

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import * as custom from '../filter/index'

Object.keys(custom).forEach(key => {
    Vue.filter(key, custom[key])
})

Vue.prototype.i18n = i18n
Vue.config.productionTip = false

// use
Vue.use(mavonEditor)
Vue.use(ElementUI)
Vue.use(UUID)
Vue.use(VueLazyload)
// Vue.use(VueAMap);



// VueAMap.initAMapApiLoader({
// 	key: 'your amap key',
// 	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
// 	v: '1.4.4'
// });

window.Http = Http
document.addEventListener('DOMContentLoaded', () => {
	try {
		new MouseEvent('test');
		return false; // No need to polyfill
	} catch (e) {
		// Need to polyfill - fall through
	}
	// Polyfills DOM4 MouseEvent
	var MouseEvent = function(eventType, params) {
		params = params || { bubbles: false, cancelable: false };
		var mouseEvent = document.createEvent('MouseEvent');
		mouseEvent.initMouseEvent(eventType, params.bubbles, params.cancelable, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		return mouseEvent;
	};
	
	MouseEvent.prototype = Event.prototype;
	
	window.MouseEvent = MouseEvent;
	
	new Vue({
		i18n,
		router,
		store,
		render: h => h(App)
	}).$mount("#app")
})
