<template>
  <header>
    <section class="header hidden-sm-and-down">
      <el-menu default-active="none" :router="true" mode="horizontal" class="el-menu-nav" text-color="#111111" active-text-color="#111111">
        <el-menu-item index="logo" :route="{path: '/' }"><img class="el-menu-image" :src="logo" alt=""></el-menu-item>
        <el-submenu index="silicon" popper-class="el-menu-nav-popper">
          <template slot="title"><span style="vertical-align: baseline;">{{ $t('header.product') }}</span></template>
          <section v-if="chipNavMenu && chipNavMenu.children" class="el-menu-second-nav">
            <el-row :gutter="0">
              <el-col :span="6" v-for="item in chipNavMenu.children.slice(0, 8)" :key="item.id" class="pl-5 pr-2 mb-4 item-menu ">
                <el-menu-item class="category-menu" :index=" 'silicon-' + item.name" :route="{path: `/silicon?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
                <div class="product-list">
                  <template v-if="item.children">
                    <el-menu-item v-for="product in item.children.slice(0, 4)" :key="product.id" :index=" 'silicon-product_' + product.name" class="product-menu" :route="{path: (item.keyword && product.keyword) ? `/silicon/${item.keyword}/${product.keyword}` : `/silicon/${product.id}` }">• {{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
                  </template>
                </div>
                <el-menu-item class="product-menu mt-2" index='silicon-product-view-all' :route="{path: `/silicon?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? '查看所有' : 'View All' }}<i class="el-icon-arrow-right" style="transform: scale(.8)"></i></el-menu-item>
              </el-col>
            </el-row>
            <div v-if="chipNavMenu && chipNavMenu.children && chipNavMenu.children.length > 8" class="text-center">
              <el-menu-item class="d-inline-block mt-2 py-1 px-5 h-auto" index='silicon-view-all' :route="{path: '/silicon'}"><i class="el-icon-s-grid" style="transform: scale(.8)"></i>{{ language === 'cn' ? '查看所有' : 'View All' }}</el-menu-item>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="software" popper-class="el-menu-nav-popper">
          <template slot="title"><span style="vertical-align: baseline;">{{ $t('header.software') }}</span></template>
          <section v-if="softwareNavMenu && softwareNavMenu.children" class="el-menu-second-nav">
            <el-row :gutter="0">
              <el-col :span="6" v-for="item in softwareNavMenu.children.slice(0, 8)" :key="item.id" class="pl-5 pr-2 mb-4 item-menu ">
                <el-menu-item class="category-menu" :index=" 'software-' + item.name" :route="{path: `/software?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
                <div class="product-list">
                  <template v-if="item.children">
                    <el-menu-item v-for="product in item.children.slice(0, 4)" :key="product.id" class="product-menu" :index=" 'software-product_' + product.name" :route="{path: (item.keyword && product.keyword) ? `/software/${item.keyword}/${product.keyword}` : `/software/${product.id}` }">• {{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
                  </template>
                </div>
                <el-menu-item class="product-menu mt-2" index='software-product-view-all' :route="{path: `/software?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? '查看所有' : 'View All' }}<i class="el-icon-arrow-right" style="transform: scale(.8)"></i></el-menu-item>
              </el-col>
            </el-row>
            <div v-if="softwareNavMenu && softwareNavMenu.children && softwareNavMenu.children.length > 8" class="text-center">
              <el-menu-item class="d-inline-block mt-2 py-1 px-5 h-auto" index='software-view-all' :route="{path: '/software'}"><i class="el-icon-s-grid" style="transform: scale(.8)"></i>{{ language === 'cn' ? '查看所有' : 'View All' }}</el-menu-item>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="solution" popper-class="el-menu-nav-popper">
          <template slot="title"><span style="vertical-align: baseline;">{{ $t('header.solution') }}</span></template>
          <section v-if="solutionNavMenu && solutionNavMenu.children" class="el-menu-second-nav">
            <el-row :gutter="0">
              <el-col :span="6" v-for="item in solutionNavMenu.children.slice(0, 8)" :key="item.id" class="pl-5 pr-2 mb-4 item-menu ">
                <el-menu-item class="category-menu" :index=" 'solution-' + item.name" :route="{path: `/solution?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
                <div class="product-list">
                  <template v-if="item.children">
                    <el-menu-item v-for="product in item.children.slice(0, 4)" :key="product.id" class="product-menu" :index=" 'solution-product_' + product.name" :route="{path: (item.keyword && product.keyword) ? `/solution/${item.keyword}/${product.keyword}` : `/solution/${product.id}` }">• {{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
                  </template>
                </div>
                <el-menu-item class="product-menu mt-2" index='solution-product-view-all' :route="{path: `/solution?category=${item.keyword ? item.keyword : item.id}` }">{{ language === 'cn' ? '查看所有' : 'View All' }}<i class="el-icon-arrow-right" style="transform: scale(.8)"></i></el-menu-item>
              </el-col>
            </el-row>
            <div v-if="solutionNavMenu && solutionNavMenu.children && solutionNavMenu.children.length > 8" class="text-center">
              <el-menu-item class="d-inline-block mt-2 py-1 px-5 h-auto" index='solution-view-all' :route="{path: '/solution'}"><i class="el-icon-s-grid" style="transform: scale(.8)"></i>{{ language === 'cn' ? '查看所有' : 'View All' }}</el-menu-item>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="support" popper-class="el-menu-nav-popper support-nav">
          <template slot="title"><span ref="supportMenu" style="vertical-align: baseline;">{{ $t('header.support') }}</span></template>
          <section class="support-menu-item" :style="{ marginLeft: supportMenuItemLeft+'px'}">
            <el-menu-item class="product-menu" index="support-document_download" route="/TechnicalSupport">{{ $t('header.document_download') }}</el-menu-item>
            <el-menu-item class="product-menu" index="support-common_question" route="/CommonProblem">{{ $t('header.common_question') }}</el-menu-item>
            <el-menu-item class="product-menu" index="support-product_consultation" route="/ProductConsultation">{{ $t('header.product_consultation') }}</el-menu-item>
          </section>
        </el-submenu>

        <el-menu-item>
          <a v-if="language === 'cn'" href="http://ir.centec.com/" target="_blank" style="font-size: 1rem!important;display: block;">投资者关系</a>
          <a v-else href="http://ir.centec.com/en" target="_blank" style="font-size: 1rem!important;display: block;">Investor Relations</a>
        </el-menu-item>

        <el-submenu index="about_us" popper-class="el-menu-nav-popper support-nav">
          <template slot="title"><span ref="aboutUsMenu" style="vertical-align: baseline;">{{ $t('header.about') }}</span></template>
          <section class="support-menu-item" :style="{ marginLeft: aboutUsMenuItemLeft+'px'}">
            <div class="d-flex justify-content-start align-items-center">
              <el-menu-item class="product-menu" index="about_us" route="/about_us">{{ $t('header.about_centec') }}</el-menu-item>
              <el-menu-item class="product-menu" index="about_us-recruitment" route="/recruitment">{{ $t('header.join') }}</el-menu-item>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <el-menu-item class="product-menu" index="about_us-news" route="/news">{{ $t('header.news') }}</el-menu-item>
              <el-menu-item class="product-menu" index="about_us-chip_agent" route="/branch">{{ $t('header.agent') }}</el-menu-item>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <el-menu-item class="product-menu" index="about_us-compliance_statement" route="/compliance_statement">{{ $t('header.compliance_statement') }}</el-menu-item>
              <el-menu-item class="product-menu" index="about_us-contact_us" route="/contact_us">{{ $t('header.contact') }}</el-menu-item>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <el-menu-item class="product-menu" index="about_us-companyHonor" route="/company_honor">{{ $t('header.honor') }}</el-menu-item>
            </div>
<!--            <div class="d-flex justify-content-start align-items-center">-->
<!--              <el-menu-item class="product-menu" index="about_us-contact_us" route="/contact_us">{{ $t('header.contact') }}</el-menu-item>-->
<!--            </div>-->
          </section>
        </el-submenu>
        <el-menu-item class="float-right float-right-menu" v-show="showSearchBar" style="width: 18% !important;">
          <div @keydown.stop>
          <el-input :placeholder="$t('technicalPage.inputContent')" v-model="search_content" class="input-with-select centec-header-search" @keyup.enter.native="goSearch">
            <el-button slot="append" icon="el-icon-search" :clearable="true" @click="goSearch"></el-button>
          </el-input>
          </div>
        </el-menu-item>
        <el-menu-item v-if="!$store.state.isLogin" v-show="!showSearchBar" class="float-right float-right-menu" @click.native=" !$store.state.isLogin? $store.state.showLoginWindow = true : '' "><i class="el-icon-user"></i></el-menu-item>
        <el-submenu index="user" v-else v-show="!showSearchBar" class="float-right float-right-menu el-menu-logout">
          <template slot="title">
            <el-button circle type="primary" class="d-inline-flex justify-content-center align-items-center p-0" style="width: 30px; height: 30px; font-size: 15px; margin: 0 -15px;">{{ getUserFirstName }}</el-button>
          </template>
          <el-menu-item class="el-menu-category" index="user-basic_information" route="/BasicInformation">{{ $t('header.memberCenter') }}</el-menu-item>
          <el-menu-item class="el-menu-category" index="" @click="isLogout">{{ $t('header.exit') }}</el-menu-item>
        </el-submenu>
        <el-menu-item class="float-right float-right-menu" v-show="!showSearchBar">
          <i class="el-icon-search" @click="changeShowSearchBar"/>
        </el-menu-item>
        <el-menu-item class="float-right float-right-menu" v-show="!showSearchBar" style="padding-left:0;">
          <el-button type="text" style="color: inherit; font-weight: normal; font-size: 12px; " @click.native="switchLanguage">{{ $t('header.language') }}</el-button>
        </el-menu-item>
      </el-menu>
    </section>
    
    <section class="header-mobile hidden-md-and-up">
      <ul>
        <li>
          <router-link to="/"><img class="el-menu-image" :src="logo" alt=""></router-link>
        </li>
      </ul>
      <ul class="right">
        <li class="drawer" @click="drawer = true">
          <i class="el-icon-s-operation"/>
        </li>
      </ul>
    </section>
    <el-drawer size="50%" title="盛科通信" :visible.sync="drawer" :with-header="false" custom-class="mobile-menu-drawer">
      <el-menu default-active="none" :unique-opened="true" :router="true" mode="vertical" class="el-menu-mobile-nav" text-color="#111" active-text-color="#3A7DC5">
        <el-submenu index="silicon">
          <template slot="title">{{ $t('header.product') }}</template>
          <section v-if="chipNavMenu && chipNavMenu.children" class="el-menu-second-nav">
            <div v-for="item in chipNavMenu.children" :key="item.id">
              <el-menu-item class="el-menu-mobile-category" :index=" 'silicon-' + item.name" :route="{path: `/silicon?category=${item.keyword ? item.keyword : item.id}`  }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
              <section v-if="item.children" class="el-menu-product_list">
                <el-menu-item v-for="product in item.children" :key="product.id" :index=" 'silicon-product_' + product.name" class="el-menu-mobile-product" :route="{path: (item.keyword && product.keyword) ? `/silicon/${item.keyword}/${product.keyword}` : `/silicon/${product.id}` }">{{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
              </section>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="software">
          <template slot="title">{{ $t('header.software') }}</template>
          <section v-if="softwareNavMenu && softwareNavMenu.children" class="el-menu-second-nav">
            <div v-for="item in softwareNavMenu.children" :key="item.id">
              <el-menu-item class="el-menu-mobile-category" :index=" 'software-' + item.name" :route="{path: `/software?category=${item.keyword ? item.keyword : item.id}`  }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
              <section v-if="item.children" class="el-menu-product_list">
                <el-menu-item v-for="product in item.children" :key="product.id" :index=" 'software-product_' + product.name" class="el-menu-mobile-product" :route="{path: (item.keyword && product.keyword) ? `/software/${item.keyword}/${product.keyword}` : `/software/${product.id}` }">{{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
              </section>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="solution">
          <template slot="title">{{ $t('header.solution') }}</template>
          <section v-if="solutionNavMenu && solutionNavMenu.children" class="el-menu-second-nav">
            <div v-for="item in solutionNavMenu.children" :key="item.id">
              <el-menu-item class="el-menu-mobile-category" :index=" 'solution-' + item.name" :route="{path: `/solution?category=${item.keyword ? item.keyword : item.id}`  }">{{ language === 'cn' ? item.name : item.en_name }}</el-menu-item>
              <section v-if="item.children" class="el-menu-product_list">
                <el-menu-item v-for="product in item.children" :key="product.id" :index=" 'solution-product_' + product.name" class="el-menu-mobile-product" :route="{path: (item.keyword && product.keyword) ? `/solution/${item.keyword}/${product.keyword}` : `/solution/${product.id}` }">{{ language === 'cn' ? product.name : product.en_name }}</el-menu-item>
              </section>
            </div>
          </section>
        </el-submenu>
        <el-submenu index="support">
          <template slot="title">{{ $t('header.support') }}</template>
          <el-menu-item class="el-menu-mobile-category" index="support-document_download" route="/TechnicalSupport">{{ $t('header.document_download') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="support-common_question" route="/CommonProblem">{{ $t('header.common_question') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="support-product_consultation" route="/ProductConsultation">{{ $t('header.product_consultation') }}</el-menu-item>
        </el-submenu>

        <el-menu-item>
          <a v-if="language === 'cn'" href="http://ir.centec.com/" target="_blank" style="color: rgb(17, 17, 17);display: block;">投资者关系</a>
          <a v-else href="http://ir.centec.com/en" target="_blank" style="color: rgb(17, 17, 17);display: block;">Investor Relations</a>
        </el-menu-item>
        
        <el-submenu index="about_us">
          <template slot="title">{{ $t('header.about') }}</template>
          <el-menu-item class="el-menu-mobile-category" index="about_us" route="/about_us">{{ $t('header.about') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="about_us-recruitment" route="/recruitment">{{ $t('header.join') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="about_us-news" route="/news">{{ $t('header.news') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="about_us-compliance_statement" route="/compliance_statement">{{ $t('header.compliance_statement') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="about_us-chip_agent" route="/branch">{{ $t('header.agent') }}</el-menu-item>
<!--          <el-menu-item class="el-menu-mobile-category" index="about_us-cooperative_partner" route="/cooperative_partner">{{ $t('header.partner') }}</el-menu-item>-->
          <el-menu-item class="el-menu-mobile-category" index="about_us-companyHonor" route="/company_honor">{{ $t('header.honor') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="about_us-contact_us" route="/contact_us">{{ $t('header.contact') }}</el-menu-item>
        </el-submenu>
        <el-menu-item v-if="!$store.state.isLogin" class="el-menu-mobile-title" index="" @click.native=" !$store.state.isLogin? $store.state.showLoginWindow = true : '' ">{{$t('header.login')}}</el-menu-item>
        <el-submenu index="user" v-else class="el-menu-logout">
          <template slot="title">{{$store.state.currentUser !== undefined ? ( $store.state.currentUser.username !== undefined ? $store.state.currentUser.username : "") : ''}}</template>
          <el-menu-item class="el-menu-mobile-category" index="user-basic_information" route="/BasicInformation">{{ $t('header.memberCenter') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="frontend-download-list" route="/DownloadList">{{ $t('personalNavigation.downloadList') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" index="frontend-security-center" route="/SecurityCenter">{{ $t('personalNavigation.securityCenter') }}</el-menu-item>
          <el-menu-item class="el-menu-mobile-category" @click="isLogout">{{ $t('header.exit') }}</el-menu-item>
        </el-submenu>
        
        <el-menu-item>
          <el-input :placeholder="$t('technicalPage.inputContent')" v-model="search_content" @keyup.enter.native="goSearch">
            <el-button slot="append" icon="el-icon-search" @click="goSearch"></el-button>
          </el-input>
        </el-menu-item>
  
        <el-menu-item v-show="!showSearchBar">
          <el-button type="text" style="color: inherit; font-weight: normal; font-size: 16px; line-height: 20px;"  @click.native="switchLanguage">
            <span style="vertical-align: bottom;">
              <el-avatar :size="16" :src="switchLanguageIcon" fit="scale-down"></el-avatar>&nbsp;&nbsp;{{ $t('header.language') }}
            </span>
          </el-button>
        </el-menu-item>
      </el-menu>
    </el-drawer>
    
    <el-dialog
      class="login-dialog"
      :title="$t('login.title')"
      :visible.sync="$store.state.showLoginWindow"
      :before-close="clearInput"
      :width="dialogWidth"
      center>
      <el-form :model="form" ref="form" class="login-form">
        <el-form-item prop="username" class="form-item" style="margin-bottom:40px;">
          <el-input type="email" v-model="form.username" auto-complete="off"
                    @focus="showLoginUsernameLabel = true,form.username == '' ? showLoginUsernameLabelPlaceHolder = false: showLoginUsernameLabelPlaceHolder = true"
                    @blur="form.username == '' ? showLoginUsernameLabel = false : showLoginUsernameLabel = true,form.username == '' ? showLoginUsernameLabelPlaceHolder = true : showLoginUsernameLabelPlaceHolder = false"
                    :placeholder="showLoginUsernameLabelPlaceHolder ? $t('login.email') : ''"/>
          <div v-show="showLoginUsernameLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('login.email')}}</div>
        </el-form-item>
        <el-form-item prop="password" class="form-item">
          <el-input :type="passwordType" v-model="form.password" auto-complete="off"
                    @focus="showLoginPasswordLabel = true,form.password == '' ? showLoginPasswordLabelPlaceHolder = false: showLoginPasswordLabelPlaceHolder = true"
                    @blur="form.password == '' ? showLoginPasswordLabel = false : showLoginPasswordLabel = true,form.username == '' ? showLoginPasswordLabelPlaceHolder = true : showLoginPasswordLabelPlaceHolder = false"
                    :placeholder="showLoginPasswordLabelPlaceHolder ? $t('login.password') : ''">
            <i class="el-icon-view el-input__icon"
               slot="suffix"
               @click="handleIconClick">
            </i>
          </el-input>
          <div v-show="showLoginPasswordLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('login.password')}}</div>
        </el-form-item>
        <el-form-item class="form-item form-remember-item">
          <el-checkbox v-model="form.remember">{{ $t('login.rememberPassword') }}</el-checkbox>
          <span class="redirect-to-forget-password" @click="$store.state.showLoginWindow = false,dialogVisibleForget=true">{{ $t('login.forgetPassword') }}</span>
        </el-form-item>
        <el-form-item class="form-item form-remember-item">
          <el-button type="primary" style="width:100%;margin-bottom:10px;" @click="submitForm" round>{{ $t('login.login') }}</el-button>
        </el-form-item>
        <el-form-item class="form-item form-remember-item" style="text-align:center;">
          <p>{{ $t('login.IsRegister') }}</p>
        </el-form-item>
        <el-form-item class="form-item form-remember-item">
          <el-button type="primary" style="width:100%" @click="register" round>{{ $t('login.register') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      class="login-dialog"
      :title="$t('login.forgetPassword')"
      :visible.sync="dialogVisibleForget"
      :before-close="clearInputForget"
      :width="dialogWidth"
      center>
      <el-form :model="forget" ref="form" class="login-form">
        <el-form-item prop="username" class="form-item" style="margin-bottom: 40px;">
          <el-input type="email" v-model="forget.username" auto-complete="new-password"
                    @focus="showForgetUsernameLabel = true,forget.username == '' ? showForgetUsernameLabelPlaceholder = false : showForgetUsernameLabelPlaceholder = true "
                    @blur="forget.username == '' ? showForgetUsernameLabel = false : showForgetUsernameLabel = true,forget.username == '' ? showForgetUsernameLabelPlaceholder = true : showForgetUsernameLabelPlaceholder = false"
                    :placeholder="showForgetUsernameLabelPlaceholder ? $t('login.email') : ''"/>
          <div v-show="showForgetUsernameLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('login.email')}}</div>
        </el-form-item>
        <el-form-item prop="captcha" class="form-item form-captcha-item" style="margin-bottom: 40px;">
          <el-input v-model="forget.captcha"
                    @focus="showForgetCaptchaLabel = true,forget.captcha == '' ? showForgetCaptchaLabelPlaceHolder = false : showForgetCaptchaLabelPlaceHolder = true"
                    @blur="forget.captcha == '' ? showForgetCaptchaLabel = false : showForgetCaptchaLabel = true,forget.captcha == '' ? showForgetCaptchaLabelPlaceHolder = true : showForgetCaptchaLabelPlaceHolder = false"
                    :placeholder="showForgetCaptchaLabelPlaceHolder ? $t('login.captcha') : ''">
            <template slot="append">
              <div style="cursor:pointer" @click="Yanma">{{word}}</div>
            </template>
          </el-input>
          <div v-show="showForgetCaptchaLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('login.captcha')}}</div>
        </el-form-item>
        <el-form-item prop="password" class="form-item" style="margin-bottom: 40px;">
          <el-input :type="passwordType" v-model="forget.password" auto-complete="new-password"
                    @focus="showForgetPasswordLabel = true,forget.password == '' ? showForgetPasswordLabelPlaceHolder = false : showForgetPasswordLabelPlaceHolder = true"
                    @blur="forget.password == '' ? showForgetPasswordLabel = false : showForgetPasswordLabel = true,forget.password == '' ? showForgetPasswordLabelPlaceHolder = true : showForgetPasswordLabelPlaceHolder = false"
                    :placeholder="showForgetPasswordLabelPlaceHolder ? $t('login.newPassword') : ''">
            <i class="el-icon-view el-input__icon"
               slot="suffix"
               @click="handleIconClick">
            </i>
          </el-input>
          <div v-show="showForgetPasswordLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('login.newPassword')}}</div>
        </el-form-item>
        <el-form-item prop="confirm_password" class="form-item">
          <el-input :type="passwordType" v-model="forget.confirm_password" auto-complete="new-password"
                    @focus="showForgetConfirmPasswordLabel = true,forget.confirm_password == '' ? showForgetConfirmPasswordLabelPlaceHolder = false : showForgetConfirmPasswordLabelPlaceHolder = true"
                    @blur="forget.confirm_password == '' ? showForgetConfirmPasswordLabel = false : showForgetConfirmPasswordLabel = true,forget.confirm_password == '' ? showForgetConfirmPasswordLabelPlaceHolder = true : showForgetConfirmPasswordLabelPlaceHolder = false"
                    :placeholder="showForgetConfirmPasswordLabelPlaceHolder ? $t('personalPage.retypePassword') : ''">
            <i class="el-icon-view el-input__icon"
               slot="suffix"
               @click="handleIconClick">
            </i>
          </el-input>
          <div v-show="showForgetConfirmPasswordLabel" style="position:absolute;top:-10px;left:10px;height:20px;line-height:20px;padding:0 5px;background-color:#fff;">{{$t('personalPage.retypePassword')}}</div>
        </el-form-item>
        <el-form-item class="form-item form-remember-item">
          <span class="redirect-to-forget-password" @click="dialogVisibleForget=false,$store.state.showLoginWindow=true">{{ $t('login.backLogin') }}</span>
        </el-form-item>
        <el-form-item class="form-item form-remember-item">
          <el-button type="primary" style="width:100%" :disabled="submitFormForgetButton" @click="submitFormForget" round>{{ $t('technicalPage.submit') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </header>
</template>

<script>
    import logo from '../assets/images/logo.png'
    import switchLanguageIcon from '../assets/images/switch_language.png'

    export default {
        name: "CentecHeader",
        data() {
            return {
                logo,
                switchLanguageIcon,
                word: '获取邮箱验证码',
                drawer: false,
                dialogVisible: false,
                dialogVisibleForget: false,
                language: this.$store.state.language,
                passwordType: 'password',
                chipNavMenu: {},
                softwareNavMenu: {},
                solutionNavMenu: {},
                form: {
                    username: '',
                    password: '',
                    remember: false
                },
                submitFormForgetButton: false,
                forget: {
                    uuid: this.$uuid.v4(),
                    username: '',
                    password: '',
                    confirm_password: '',
                    captcha: ''
                },
                loginname: '',
                isLogin: false,
                logoutClass: {
                    display: 'none'
                },
                dialogWidth: '0px',
                supportMenuItemLeft: 426,
                aboutUsMenuItemLeft: 500,
                search_content: '',
                showSearchBar: false,
                Treamm: true,
                showLoginUsernameLabel: false,
                showLoginUsernameLabelPlaceHolder: true,
                showLoginPasswordLabel: false,
                showLoginPasswordLabelPlaceHolder: true,
                showForgetUsernameLabel: false,
                showForgetUsernameLabelPlaceholder: true,
                showForgetPasswordLabel: false,
                showForgetPasswordLabelPlaceHolder: true,
                showForgetConfirmPasswordLabel: false,
                showForgetConfirmPasswordLabelPlaceHolder: true,
                showForgetCaptchaLabel: false,
                showForgetCaptchaLabelPlaceHolder: true,
                timer: '',
                currentLanguage: this.$store.state.language
            }
        },
        computed: {
            getCaptchaImagePassword() {
                return `/api/v1/get_captcha_image?uuid=${this.forget?.uuid}`
            },
            getUserFirstName() {
                return this.$store.state?.currentUser?.member_name?.slice(0, 1)?.toUpperCase()
            }
        },
        created() {
            if (this.language != 'cn') {
                this.word = 'Get email verification code'
            }
            Http.get('/api/v1/get_nav_menu').then(result => {
                if (result.code === 0) {
                    this.chipNavMenu = result.data?.filter(item => item.id === 1)?.[0]
                    this.softwareNavMenu = result.data?.filter(item => item.id === 2)?.[0]
                    this.solutionNavMenu = result.data?.filter(item => item.id === 3)?.[0]
                }
            })
            this.setDialogWidth()
            Http.post('/api/v1/member_info').then(result => {
                if (Number.parseInt(result?.code) === -2) {
                    _this.$store.commit('logout')
                    _this.$store.commit('isLoginFalse')
                    _this.$router.push({ path: '/' })
                    _this.$message.error(result?.message)
                }
            })
            let flag = this.$route.query?.flag
            if (flag && flag == "dk") {
                this.dialogVisibleForget = true
            }
            if (flag && flag == "unsub") {
                this.$confirm(`${this.i18n.t('headTips.newsletter')}`, `${this.i18n.t('activate.tips')}`, {
                    confirmButtonText: `${this.i18n.t('activate.determine')}`,
                    cancelButtonText: `${this.i18n.t('activate.cancel')}`,
                    type: 'warning'
                }).then(() => {
                    let params = {
                        email: this.$route.query?.email
                    }
                    Http.post('/api/v1/unsub_email', params).then(result => {
                        if (result?.code === 0) {
                            this.$message({
                                message: `${this.i18n.t('headTips.unsubscribeSuccess')}`,
                                type: 'success'
                            })
                            window.location.href = '/'
                        } else {
                            _this.$message.error(result?.message)
                        }
                    }, error => {
                        console.info(error)
                    })
                }).catch(() => {
                })
            }
        },
        mounted() {
            if (this.$store.state.currentUser?.username !== undefined) {
                this.$store.commit('isLoginTrue')
                this.logoutClass.display = "inline-block"
            }
            window.onload = () => {
                this.supportMenuItemLeft = this.$refs.supportMenu?.offsetParent?.offsetLeft
                this.aboutUsMenuItemLeft = this.$refs.aboutUsMenu?.offsetParent?.offsetLeft - 37
            }
            window.onresize = () => {
                return (() => {
                    this.supportMenuItemLeft = this.$refs.supportMenu?.offsetParent?.offsetLeft
                    this.aboutUsMenuItemLeft = this.$refs.aboutUsMenu?.offsetParent?.offsetLeft - 37
                    this.setDialogWidth()
                })()
            }
           // this.timer = setInterval(this.get, 10000);
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            switchLanguage() {
                let targetLanguage = (this.currentLanguage === 'cn' ? 'en' : 'cn')
                this.language = targetLanguage
                this.$i18n.locale = targetLanguage
                this.$store.commit('changeLanguage', targetLanguage)
                window.location.reload(true)
                return false
            },
            refreshCaptchaImage() {
                this.forget.uuid = this.$uuid.v1()
            },
            handleIconClick() {
                return this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
            },
            clearInput() {
                this.form.username = ""
                this.form.password = ""
                this.$store.state.showLoginWindow = false
            },
            clearInputForget() {
                this.forget.username = ""
                this.forget.password = null
                this.forget.confirm_password = null
                this.forget.captcha = null
                this.dialogVisibleForget = false
            },
            submitForm() {
                if (this.form.username === "") {
                    this.$message({
                        message: `${this.i18n.t('footer.inputEmail')}`,
                        type: 'warning'
                    });
                    return false
                }
                if (this.form.password === "") {
                    this.$message({
                        message: `${this.i18n.t('activate.passwordTipsTwo')}`,
                        type: 'warning'
                    });
                    return false
                }
                let _this = this
                this.form.username = this.trim(this.form.username)
                this.form.password = this.trim(this.form.password)
                Http.post('/api/v1/login', _this.form).then(result => {
                    if (result?.code === 0) {
                        this.clearInput()
                        _this.$store.state.showLoginWindow = false
                        // _this.$message({
                        // 	message: '登录成功！',
                        // 	type: 'success'
                        // });
                        _this.$store.commit('isLoginTrue')
                        _this.loginname = result.data.username
                        _this.$store.commit('changeLogin', result?.data?.token)
                        if (result?.data?.token) {
                            delete result.data.token
                        }
                        _this.$store.commit('changeCurrentUser', result.data)
                        location.reload()
                    } else {
                        _this.$message.error(result?.message)
                        _this.form.captcha = ""
                        _this.refreshCaptchaImage()
                    }
                }, error => {
                    console.info(error)
                })
            },
            submitFormForget() {
                if (this.submitFormForgetButton){
                  return;
                }
                this.submitFormForgetButton = true
                if (this.forget.username === "" || this.forget.username == null) {
                    this.$message({
                        message: `${this.i18n.t('consultation.verificationEmailOne')}`,
                        type: 'warning'
                    });
                    this.submitFormForgetButton = false
                    return false
                }
                if (this.forget.captcha === "" || this.forget.captcha == null) {
                    this.$message({
                        message: `${this.i18n.t('consultation.verificationCaptcha')}`,
                        type: 'warning'
                    });
                    this.submitFormForgetButton = false
                    return false
                }
                let _this = this
                if (_this.forget.password != _this.forget.confirm_password) {
                    this.$message.warning(`${this.i18n.t('activate.diff')}`)
                    this.submitFormForgetButton = false
                    return false
                }
                if (!this.validPassword(_this.forget.password)) {
                    this.$message.warning(`${this.i18n.t('activate.passwordTips')}`)
                    this.submitFormForgetButton = false
                    return false
                }

                Http.post('/api/v1/reset_forget_password', _this.forget).then(result => {
                    if (result?.code === 0) {
                        this.$message({
                            message: result?.message,
                            type: 'success'
                        });
                        this.clearInputForget()
                        this.dialogVisibleForget = false
                        window.location.href = '/'
                    } else {
                        _this.$message.error(result?.message)
                    }
                    this.submitFormForgetButton = false
                }, error => {
                    console.info(error)
                    this.submitFormForgetButton = false
                })
            },
            trim(str) { //删除左右两端的空格
                return str.replace(/(^\s*)|(\s*$)/g, "");
            },
            isLogout() {
                this.$confirm(`${this.i18n.t('headTips.logout')}`, `${this.i18n.t('activate.tips')}`, {
                    confirmButtonText: `${this.i18n.t('activate.determine')}`,
                    cancelButtonText: `${this.i18n.t('activate.cancel')}`,
                    type: 'warning'
                }).then((action) => {
                    if (action === 'confirm') {
                        this.logout()
                    }
                }).catch(() => {

                })
            },
            logout() {
                this.$store.commit('logout')
                this.$store.commit('isLoginFalse')
                this.$router.push({ path: '/' })
            },
            setDialogWidth() {
                var val = document.body.clientWidth
                const def = 800 // 默认宽度
                if (val < def) {
                    this.dialogWidth = '100%'
                } else {
                    this.dialogWidth = '29rem'
                }
            },
            register() {
                this.$store.state.showLoginWindow = false
                this.$router.push({ path: '/UserRegister' })
            },
            goSearch() {
              this.showSearchBar = false
              this.drawer = false
                if (!this.search_content) {
                    return false
                } else {
                    this.$router.push({ path: '/Search?search_content=' + this.search_content })
                    this.search_content = ""
                }
            },
            changeShowSearchBar() {
                this.showSearchBar = true
            },
            Yanma() {
                if (!this.Treamm) {
                    return false
                }
                let _this = this
                if (_this.forget.username == '' || _this.forget.username == null) {
                    this.$message.warning(`${this.i18n.t('consultation.verificationEmailOne')}`)
                    return false
                }

                let paramas = {
                    username: _this.forget.username,
                    username_uuid: _this.forget.uuid,
                }
                Http.post('/api/v1/get_reset_forget_password_email', paramas).then(result => {
                    if (result?.code === 0) {
                        this.$message.success(result?.message)
                    } else {
                        this.$message.error(result?.message)
                    }
                })

                if (_this.Treamm) {
                    this.Treamm = false;
                    let langtime = 60;
                    let than = this
                    let a = setInterval(function () {
                        if (langtime == 0) {
                            than.Treamm = true;
                            if (_this.language != 'cn') {
                                than.word = "Get email verification code";
                            } else {
                                than.word = "获取邮箱验证码";
                            }
                            clearInterval(a);
                        } else {
                            langtime--;
                            console.log(langtime);
                            if (_this.language != 'cn') {
                                than.word = `Get After ${langtime} Seconds`;
                            } else {
                                than.word = `${langtime}秒后再获取`;
                            }
                        }
                    }, 1000);
                }
            },
            validPassword(value) {
                const Reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,}$/
                if (!value) {
                    this.$message.warning(`${this.i18n.t('activate.passwordTipsTwo')}`)
                }
                return Reg.test(value)
            },
            trueFalse(one, two) {
                one = true
                two = false
            },
            falseTrue(one, two) {
                one = false
                two = true
            },
            get() {
                let _this = this
                Http.post('/api/v1/member_info').then(result => {
                    if (Number.parseInt(result?.code) === -2) {
                        _this.$store.commit('logout')
                        _this.$store.commit('isLoginFalse')
                        _this.$router.push({ path: '/' })
                        _this.$message.error(result?.message)
                    }
                })
            }
        }
    }
</script>
<style lang="scss">
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }

  @media screen and (max-width: 768px) {
    .login-dialog {
      height: 100%;
      margin-top: 0 !important;
    }
    .el-message-box {
      width: 100% !important;
    }
  }

  .login-dialog {
    .el-dialog__header {
      background-color: #F4F4F6 !important;
    }
  }

  // web 端二级菜单样式
  .header {
    .el-menu-nav {
      .el-menu-item {
        border-bottom: none !important;
      }

      .el-submenu {
        .el-submenu__title {
          border-bottom: none !important;
          font-size: 1rem !important;
          padding: 0 15px !important;
          .el-submenu__icon-arrow {
            display: none !important;
          }
        }
      }

      .float-right-menu {
        color: #111 !important;
        border-bottom: none !important;
        padding: 0 10px !important;
      }
    }
  }


  .el-menu-nav-popper {
    left: 0 !important;
    right: 0 !important;
    background: #3A7DC5;

    .el-menu {
      display: block;
      width: 90%;
      margin: 2rem auto 1rem auto;
      background: transparent;
      box-shadow: none;

      .el-menu-item {
        background: transparent;
        color: #fff !important;
        font-size: 1rem !important;
        line-height: 1.5rem;
        padding: 0 15px;
        width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .el-menu-second-nav {
        margin: 0 20px;

        .item-menu {
          border-right: 1px solid rgba(255, 255, 255, 0.3);

          &:last-child, &:nth-child(4n) {
            border-right-color: transparent;
          }
        }

        .category-menu {
          background: transparent;
          display: block !important;
          color: #fff !important;
          font-size: 1rem !important;
          line-height: 1.5rem;
          height: 3rem;
          margin: 0;
          font-weight: 400;
          display: -webkit-box !important;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal !important;
        }

        .product-list {
          height: 8rem;
        }

        .product-menu {
          background: transparent;
          display: block !important;
          color: #fff !important;
          font-size: .8rem !important;
          line-height: 2rem;
          height: 2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          i {
            color: #fff !important;
          }
        }

        .const-menu-item {
          background: transparent;
          color: #fff !important;
          font-size: .8rem !important;
          line-height: 2rem;
          height: 2rem;
          margin-bottom: 1rem;
          font-weight: 400;
        }

      }
    }
  }

  // 移动端菜单栏抽屉超出滚动
  .mobile-menu-drawer {
    overflow-y: auto !important;
  }

  .centec-header-search {
    .el-input-group__append, .el-input-group__prepend {
      background: #fff;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-left: none;
    }

    .el-input__inner {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-right: none;
      outline: none;
    }

    .el-input__inner:focus {
      outline: none;
      border-color: rgb(220, 223, 230);
    }

    .el-input__inner:hover {
      outline: none;
      border-color: rgb(220, 223, 230);
    }
  }

  .el-drawer__body {
    .el-menu-second-nav {
      .el-menu-mobile-category {
        font-weight: bold !important;
      }
    }

    .el-menu-product_list {
      li {
        font-size: 12px;
        padding-left: 60px !important;
      }
    }
  }
</style>

<style scoped lang="scss">

  // web 端二级菜单样式
  .header {
    .el-menu-nav {
      display: block;
      width: 90%;
      height: 60px;
      line-height: 60px;
      font-size: 1.2rem;
      margin: 0 auto;
      padding: 0;
      border: none;
      color: #111111;

      .el-menu-image {
        display: block;
        height: 30px;
        margin: 15px 20px 15px 0;
        width: auto;
      }
    }
  }

  // 移动端菜单样式
  .header-mobile {
    width: 90%;
    height: 60px;
    line-height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
    align-items: center;

    .el-menu-image {
      display: block;
      height: 40px;
      margin: 10px 0;
      width: auto;
    }
  }

  .login-dialog {
    .login-form {
      border-radius: 5px;
      background: #fff;
      padding: 1rem 2rem;
      margin: 1rem;

      .form-item {
        margin-bottom: 0;

        .redirect-to-forget-password {
          float: right;
          color: #3A7DC5;
          font-size: 12px;
          text-decoration: none;
          cursor: pointer;
        }

        .form-submit {
          width: 100%;
        }
      }
    }
  }

</style>
