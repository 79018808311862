import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
    header:{
        home: 'Home',
        product: 'Silicon',
        silicon: 'Silicon',
        software: 'Software',
        solution: 'Solutions',
        support: 'Technical Support',
        document_download: 'Document Center',
        common_question: 'FAQ',
        product_consultation: 'Product Inquiry',
        about: 'About Us',
        about_centec: 'About Centec',
        join: 'Career',
        news: 'Press Release',
        compliance_statement: 'Compliance Statement',
        agent: 'Worldwide Sales Reps',
        partner: 'Partners',
        honor: 'Awards & Recognition',
        contact: 'Contact Us',
        login: 'Login',
        language: '中文',
        big_event: 'Latest Events',
        load_more: 'Learn More',
        phone: 'Tel：',
        address: 'Address：',
        zip_code: 'ZipCode：',
        fax: 'Fax：',
        document: 'Documents',
        all_Search: 'Site Search',
        memberCenter: 'Membership Center',
        exit: 'Exit',
    },
    quickLink:{
        contact: 'Contact Us',
        media: 'Social Media',
        feedback: 'Feedback',
        
        support: 'Support',
        resource: 'Resources',
        
        activity: 'News & Exhibition',
        news: 'News',
        exhibition: 'Exhibition',
        
        cooperation: 'Cooperation & Agent',
        partner: 'Partners',
        agent: 'Worldwide Sales Reps',
        
        join: 'Join Us',
        socialRecruitment: 'Social Recruitment',
        campusRecruitment: 'Campus Recruitment',
        
        production: 'Production & Solution',
        latestSolution: 'Latest Production & Solution'
    },
    login:{
        title: 'Member Login',
        email: 'Email',
        password: 'Password',
        newPassword: 'New Password',
        passwordTip: 'Password',
        captcha: 'Captcha',
        rememberPassword: 'Remember Password',
        forgetPassword: 'Forgot Password',
        login: 'Login',
        submitForget: 'Send verification message',
        backLogin: 'Back to Login',
        register: 'Register',
        IsRegister: 'Not Yet Registered?',
        passwordTips: "Password (comprising at least 6 upper and lower case letters + numbers)"
    },
    register: {
        memberRegister: 'User Registration',
        email: 'Email（Required）',
        emailCode: 'Email Code（Required）',
        password: 'Password（Required）（comprising at least 6 upper and lower case letters + numbers）',
        retypePassword: 'Confirm Password（Required）',
        memberName:'Member Name（Required）',
        companyName:'Company Name（Required）',
        companyWebsite: 'Company WebSite',
        position:'Position',
        phone: 'Phone',
        register: 'Register',
        diffPassword: 'Two passwords are inconsistent'
    },
    indexPage: {
        aboutTitle: 'Redefining the future of networking to enable Faster, Assured and Secure Network Connectivity for the Digital Era.',
        aboutDetails: 'Centec Communications is an established Ethernet switch silicon vendor founded in Suzhou industrial park, January 2005. Centec has constantly endeavored to provide Ethernet switch silicon and SDN/whitebox solutions and has developed 7 generations of switch silicon and SDN/whitebox solutions that are widely deployed in carrier grade Ethernet, enterprise/campus networking and data center networking around the world.',
        aboutButton: 'Learn More',
        productionTitle: 'Our Products',
        productionIntroduction: 'End-to-End Ethernet Switch Silicons and Network Solutions',
        productionViewAll: 'View all products',
        productionSiliconTitle: 'Silicon',
        productionSiliconDescription: 'High performance and strong reliability for next generation intelligent network',
        productionSolutionTitle: 'Solutions',
        productionSolutionDescription: 'Data Center, Security, media distribution, etc.',
        productionSoftwareTitle: 'Software',
        productionSoftwareDescription: 'Unified SDK and mature system software for multiple applications',
        bigEventTitle: 'Latest Events'
    },
    footer:{
        address: 'Address',
        zip: 'ZipCode',
        phone: 'Tel',
        fax: 'Fax',
        businessSupport: 'Business Support',
        technicalSupport: 'Technical Support',
        copyright: `Copyright © 2005 - ${new Date().getFullYear()} Suzhou Centec Communications Co., Ltd. All rights reserved.`,
        wechat: 'Official WeChat',
        sina: 'Centec Weibo',
        interestOne: 'If you are interested in Centec’s products, applications and latest information <br/> please subscribe to Centec’s Newsletter',
        inputEmail: 'please enter your email address here.',
        contactWay: 'Contact',
        success: 'Subscription succeeded!',
    },
    rightNavigation: {
        aboutUs: 'About Us',
        recruitment: 'Career',
        news: 'Press Release',
        chipAgent: 'Worldwide Sales Reps',
        cooperativePartner: 'Partners',
        companyHonor: 'Awards & Recognition',
        latestBigEvent: 'Latest Events',
        loadMore: 'Load More',
        contactUs: 'Contact Us'
    },
    personalNavigation: {
        personal: 'Membership Center',
        basicInformation: 'My Information',
        downloadList: 'Download History',
        securityCenter: 'Security Center',
    },
    technicalNavigation: {
        support: 'Technical Support',
        download: 'Document Center',
        commonProblem: 'FAQ',
        productConsultation: 'Product Inquiry'
    },
    personalPage: {
        basicInformation: 'Basic Information',
        recentLogin: 'Recent Login',
        updateInformation: 'Updates',
        telephone: 'Tel',
        email: 'Email',
        company: 'Company',
        homePage: 'Home Page',
        partnerCenter: 'Partner Center',
        basicInfo: 'Basic Information' ,
        downloadList: 'Download History',
        viewBySeries: 'View By Series',
        sortByDate: 'Sort By Date',
        downloadDate: 'Download Date',
        resourceName: 'Resource Name',
        format: 'Format',
        version: 'Version',
        operation: 'Actions',
        check: 'View',
        name: 'Name',
        securityCenter: 'Security Center',
        changePassword: 'Change Password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        retypePassword: 'Confirm Password',
        submit: 'Confirm Changes',
        position: 'Position',
        district: 'District',
        company_website: 'Company Website',
        project_name: 'Project Name',
        activate_user: 'User Activation',
        set_password: 'Set Password',
        confirm: 'Submit',
        account: 'Email',
        update_info: 'Edit Personal Information',
        submitInfo: 'Update',
        resetPassword: 'Reset Password',
        totalStationSearch: 'Total Station Search'
    },
    technicalPage: {
        resourceDownload: 'Document Center',
        search: 'Search',
        hotResource: 'Hot Resource',
        resourceList: 'Resource List',
        releaseDate: 'Release Date',
        resourceName: 'Resource Name',
        fileSize: 'File Size',
        format: 'Format',
        version: 'Version',
        operation: 'Actions',
        check: 'View',
        homePage: 'Home Page',
        support: 'Support',
        download: 'Resource Download',
        commonProblem: 'FAQ',
        productConsultation: 'Product Inquiry',
        hotProblem: 'FAQ',
        productConsultationIntro: 'Hello! Please leave your contact information and your specific product requests here. We will contact you as soon as possible.',
        name: 'Name(Required)',
        phone: 'Phone',
        companyName: 'Company(Required)',
        position: 'Position(Required)',
        businessEmail: 'Business Email Address(Required)',
        businessTel: 'Business Phone Number',
        companyWebsite: 'Company Website',
        productsOfInterest: 'Product(s) of interest(Required)',
        yourSuggest: 'Your Request or Suggestion(Required)',
        isSubscribe: 'Interested in Shengke product application and latest information, willing to subscribe to Shengke newsletter.',
        submit: 'Submit',
        captcha: 'Captcha',
        downloadNow: 'Download Now',
        fileLevel: 'File Level',
        searchOne: 'Product or Keywords',
        searchTwo: 'Relevance',
        searchThree: 'Search by Product',
        searchFour: 'Search by Keyword',
        searchFive: 'Product Categories',
        searchSix: 'Product Series',
        searchSeven: 'Please Select a Product',
        inputContent: 'Please Enter',
        newRelease: 'New Release',
        allRelatedProducts: 'All related products',
        more: 'More',
        Documentation: 'documentation',
        noRelatedProducts: 'No Related Products',
        addTime: 'Add Time',
        documentLevel: 'Document Level',
        documentSize: 'Document Size',
        fileFormat: 'File Format',
        amountDownloads: 'Number of Downloads',
        clickCount: 'Number of Clicks',
        documentDescribe: 'Document Description'
    },
    joinCenter: {
        join: 'Career',
        joinTwo: 'Join Centec and create the future of open network together！',
        recruitmentType: 'Employment Type',
        workPlace: 'Location',
        jobType: 'Job Type',
    },
    chipAgentPage: {
        briefIntroduction: 'Centec has partnered with top-tier manufacturer’s representatives around the world. We are constantly expanding the representation. If you are interested in our products and being the representation of us, please contact us at sales@centec.com.'
    },
    cooperativePartnerPage: {
        briefIntroduction: 'Centec has partnered with leading providers of complementary software and hardware component to ensure interoperability, reduce time to market and lower the R&D cost for our customers.'
    
    },
    consultation: {
        verificationEmailOne: 'Email cannot be empty',
        verificationEmailTwo: 'Please enter the correct email format',
        verificationName: 'Please enter your name',
        verificationCompanyName: 'Please enter your company name',
        verificationPosition: 'Please enter your position',
        verificationEmailThree: 'Please enter business email',
        verificationType: 'Please select products of interest',
        verificationSuggest: 'Please enter your requirements / suggestions',
        error: 'Getting information failure！',
        verificationNum: 'Please enter number！',
        verificationPhone: 'Please enter your phone number！',
        verificationCaptcha: 'Please enter captcha！',
        verificationConfirmPassword: 'Please confirm password！',
        verificationsCaptcha: 'Please enter email verification code！',
    },
    activate: {
        tips: 'Tips',
        determine: 'Ok',
        cancel: 'Cancel',
        enter: 'Please enter all！',
        diff: 'Two password entries are inconsistent！',
        passwordTips: 'Password (comprising at least 6 upper and lower case letters + numbers)!',
        success: 'Account activation succeeded, please go to login!',
        passwordTipsTwo: 'Please fill in the password！',
        downloadTips: 'Your download request is in progress. Please wait for a while.'
    },
    update: {
        verificationName: 'Name cannot be empty！！',
        verificationCompanyName: 'Company name cannot be empty！',
        verificationPhone: 'Mobile number cannot be empty！',
        success: 'Update information succeeded！',
        error: 'Failed to update information！',
    },
    headTips: {
        newsletter: 'Whether to unsubscribe from Centec Newsletter?',
        unsubscribeSuccess: 'Unsubscribe successfully！',
        unsubscribeError: 'Unsubscribe failed！',
        logout: 'Do you want to log out of this account?',
        subError: 'Subscription failed！',

    },
    footTips: {
        verificationEmail: 'Please enter the correct email',
        error: 'Subscribe error，please try later'
    },
    ...enLocale
}

export default en