import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const cn = {
    header: {
        home: '首页',
        product: '芯片',
        silicon: '芯片',
        software: '软件',
        solution: '解决方案',
        support: '技术支持',
        document_download: '文档中心',
        common_question: '常见问题',
        product_consultation: '产品咨询',
        about: '关于我们',
        about_centec: '关于盛科',
        join: '招贤纳士',
        news: '盛科动态',
        compliance_statement: '合规声明',
        agent: '分支机构',
        partner: '合作伙伴',
        honor: '公司荣誉',
        contact: '联系我们',
        login: '登录',
        language: 'English',
        big_event: '最新大事件',
        load_more: '详细了解',
        phone: '电话：',
        address: '地址：',
        zip_code: '邮编：',
        fax: '传真：',
        document: '文档',
        all_Search: '全站搜索',
        memberCenter: '会员中心',
        exit: '退出',
    },
    quickLink:{
        contact: '联系我们',
        media: '社交媒体',
        feedback: '需求反馈',
        
        support: '技术支持',
        resource: '资源中心',
        
        activity: '新闻与会展',
        news: '新闻活动',
        exhibition: '会展活动',
        
        cooperation: '合作与代理',
        partner: '合作伙伴',
        agent: '盛科代理',
        
        join: '加入盛科',
        socialRecruitment: '社会招聘',
        campusRecruitment: '校园招聘',
        
        production: '产品与方案 ',
        latestSolution: '最新产品及方案'
    },
    login:{
        title: '会员登录',
        email: '邮箱',
        password: '密码',
        newPassword: '新密码',
        captcha: '验证码',
        rememberPassword: '记住密码',
        forgetPassword: '忘记密码',
        login: '立即登录',
        submitForget: '发送验证邮件',
        backLogin: '返回登录',
        register: '注册',
        IsRegister: '尚未注册？',
        getEmailVerificationCode:'获取邮箱验证码',
        passwordTips: "密码（大、小写字母+数字，至少6个字符）"
    },
    register: {
        memberRegister: '用户注册',
        email: '邮箱（必填）',
        emailCode: '邮箱验证码（必填）',
        password: '密码（必填）大、小写字母+数字，至少6个字符',
        retypePassword: '重复密码（必填）',
        memberName:'会员姓名（必填）',
        companyName:'公司名称（必填）',
        companyWebsite: '公司网站',
        position:'职位',
        phone: '手机',
        register: '注册',
        diffPassword: '两次密码不一致！',
        verWebSite: ''
    },
    indexPage: {
        aboutTitle: '创新引领未来',
        aboutDetails: '盛科始终以引领为目标，在网络高性能连接、网络高安全、网络确定性、网络可视化中做出多项重要创新，已经形成了面向5G承载、数据中心、企业网和工业网络的丰富产品序列，基于盛科方案的产品广泛部署，并且成熟应用。盛科网络愿和广大合作伙伴携手奋进，共同打造面向未来的的下一代新型网络。',
        aboutButton: '了解盛科文化',
        productionTitle: '我们的产品',
        productionIntroduction: '提供端到端全系列网络芯片以及解决方案',
        productionViewAll: '查看所有产品',
        productionSiliconTitle: '芯片',
        productionSiliconDescription: '高性能、高安全、确定性，打造下一代智能网络',
        productionSolutionTitle: '解决方案',
        productionSolutionDescription: '覆盖数据中心、安全监控、流媒体分发多个领域',
        productionSoftwareTitle: '软件',
        productionSoftwareDescription: '统一SDK套件，先进网络操作系统，广泛应用',
        bigEventTitle: '最新大事件'
    },
    footer:{
        address: '地址',
        zip: '邮编',
        phone: '电话',
        fax: '传真',
        businessSupport: '商务支持',
        technicalSupport: '技术支持',
        copyright: `版权所有 © 苏州盛科通信股份有限公司 2005 - ${new Date().getFullYear()} 保留一切权利  `,
        wechat: '官方微信',
        sina: '盛科微博',
        interestOne: '对盛科产品应用和最新资讯感兴趣，<br/>愿意订阅盛科Newsletter',
        inputEmail: '请输入您的邮箱',
        contactWay: '联系方式',
        success: '订阅成功!',
    },
    rightNavigation: {
        aboutUs: '关于盛科',
        recruitment: '招贤纳士',
        news: '公司动态',
        chipAgent: '分支机构',
        cooperativePartner: '合作伙伴',
        companyHonor: '公司荣誉',
        latestBigEvent: '最新大事件',
        loadMore: '详细了解',
        contactUs: '联系我们'
    },
    personalNavigation: {
        personal: '会员中心',
        basicInformation: '基本信息',
        downloadList: '下载历史',
        securityCenter: '安全中心',
    },
    technicalNavigation: {
        support: '技术支持',
        download: '文档中心',
        commonProblem: '常见问题',
        productConsultation: '产品咨询'
    },
    personalPage: {
        basicInformation: '基本信息',
        recentLogin: '最近登录',
        updateInformation: '更新信息',
        telephone: '电话',
        email: '邮箱',
        company: '公司',
        homePage: '主页',
        partnerCenter: '会员中心',
        basicInfo: '基本信息',
        downloadList: '下载历史',
        viewBySeries: '按系列查看',
        sortByDate: '时间排序',
        downloadDate: '下载日期',
        resourceName: '资源名称',
        format: '格式',
        version: '版本号',
        operation: '操作',
        check: '查看',
        securityCenter: '安全中心',
        changePassword: '修改密码',
        currentPassword: '当前密码',
        newPassword: '新密码',
        retypePassword: '确认密码',
        submit: '确认修改',
        position: '职位',
        name: '姓名',
        district: '地域',
        company_website: '公司网址',
        project_name: '项目名称',
        activate_user: '用户激活',
        set_password: '设置密码',
        confirm: '确认',
        account: '邮箱',
        update_info: '修改个人信息',
        submitInfo: '更新信息',
        resetPassword: '重置密码',
        totalStationSearch: '全站搜索'
    },
    technicalPage: {
        resourceDownload: '文档下载',
        search: '搜索',
        hotResource: '热点推荐',
        resourceList: '资源列表',
        releaseDate: '发布日期',
        resourceName: '资源名称',
        fileSize: '文件大小',
        format: '格式',
        version: '版本号',
        operation: '操作',
        check: '查看',
        homePage: '主页',
        support: '技术支持',
        download: '资源下载',
        commonProblem: '常见问题',
        productConsultation: '产品咨询',
        hotProblem: '热门问题',
        productConsultationIntro: '您好，请在此处留下您的联系方式和具体产品需求，我们会尽快与您联系。',
        name: '姓名（必填）',
        companyName: '公司名称（必填）',
        position: '职位（必填）',
        businessEmail: '商务邮箱（必填）',
        phone: '手机',
        businessTel: '商务电话',
        companyWebsite: '公司网址',
        productsOfInterest: '感兴趣的产品（必填）',
        yourSuggest: '您的需求建议（必填）',
        isSubscribe: '对盛科产品应用和最新资讯感兴趣，愿意订阅盛科Newsletter。',
        submit: '提交',
        captcha: '验证码',
        downloadNow: '立即下载',
        fileLevel: '文档等级',
        searchOne: '按产品或关键词查找',
        searchTwo: '基于用户关联性推荐',
        searchThree: '按产品查找',
        searchFour: '按关键词查找',
        searchFive: '产品大类',
        searchSix: '产品系列',
        searchSeven: '请选择产品',
        inputContent: '请输入内容',
        newRelease: '新发布',
        allRelatedProducts: '全部关联产品',
        more: '更多',
        Documentation: '说明文档',
        noRelatedProducts: '暂无关联产品',
        addTime: '添加时间',
        documentLevel: '文档等级',
        documentSize: '文档大小',
        fileFormat: '文件格式',
        amountDownloads: '下载次数',
        clickCount: '点击次数',
        documentDescribe: '文件描述'
    },
    joinCenter: {
        join: '招贤纳士',
        joinTwo: '加入盛科，共同创造开放网络的未来！',
        recruitmentType: '招聘类型',
        workPlace: '工作地点',
        jobType: '岗位类型',
    },
    chipAgentPage: {
        briefIntroduction: '盛科在全球范围成立分支机构，并且我们在不断的扩展我们的销售网络。如果您对我们的产品有兴趣，请和我们联系 sales@centec.com。'
    },
    cooperativePartnerPage: {
        briefIntroduction: '盛科已经与世界领先的配套软件和硬件提供商建立合作关系来保证足够的互通性， 帮助客户减少产品推向市场的时间和降低研发的费用。'
    },
    consultation: {
        verificationEmailOne: '邮箱不能为空',
        verificationEmailTwo: '请输入正确的邮箱格式',
        verificationName: '请输入您的姓名',
        verificationCompanyName: '请输入您所在的公司名称',
        verificationPosition: '请输入您的职位',
        verificationEmailThree: '请输入商务邮箱',
        verificationType: '请选择感兴趣的产品',
        verificationSuggest: '请输入您的需求/建议',
        error: '获取信息失败,请稍后再试！',
        verificationNum: '请输入数字！',
        verificationPhone: '请输入您的电话号码！',
        verificationCaptcha: '请输入验证码！',
        verificationConfirmPassword: '请确认密码！',
    },
    activate: {
        tips: '提示',
        determine: '确定',
        cancel: '取消',
        enter: '请输入完整！',
        diff: '两次密码输入不一致！',
        passwordTips: '密码必须包含大写字母，小写字母和数字，至少6个字符!',
        success: '账户激活成功，请前往登录!',
        passwordTipsTwo: '请填写密码！',
        downloadTips: '文件处理中，完成后将自动开始下载，请耐心等待。'
    },
    security: {
        success: '密码修改成功！',
        error: '密码修改失败！',
    },
    update: {
        verificationName: '姓名不能为空！',
        verificationCompanyName: '公司名称不能为空！',
        verificationPhone: '手机号码不能为空！',
        success: '更新信息成功！',
        error: '更新信息失败！',
    },
    headTips: {
        newsletter: '是否退订盛科Newsletter?',
        unsubscribeSuccess: '退订成功！',
        unsubscribeError: '退订失败！',
        logout: '是否退出此账号？',
        subError: '订阅失败！',
    },
    footTips: {
        verificationEmail: '请输入正确的邮箱',
        error: '订阅失败，请稍后再试'
    },
    ...zhLocale
}

export default cn
